import { computed, Injectable } from '@angular/core';
import { useStore } from 'ngx-unificator/store';
import { GlobalStoreName } from '../../../core/store';
import { IValentinePage, ValentineStoreEntity } from '../../../core/store/valentine.store';
import { BasePromo } from '../base-promo';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { ITournament } from '../../tournaments/tournaments.interface';
import { filter, first, map, tap } from 'rxjs/operators';
import { EventType, WelcomePromoModal } from '../base-promo.interface';
import { GameCategory } from '../../../core/services/games/game-category';
import { BonusStage } from '../../../core/services/user/data/user-bonuses.data';
import { BonusesStatuses } from '../../../core/services/groups.service';

export enum ValentineAction {
  GO_TO_DEPOSIT = 'deposit',
  TOURNAMENTS = 'tournaments',
  PROMO_AUTH = 'promo-auth',
  GET_BONUS = 'get-bonus',
  GO_TO_BONUSES = 'bonuses',
  GAMES = 'games',
}

export const ALREADY_SWIPE_PROMO_STR = 'alreadySwipePromo';

@Injectable({
  providedIn: 'root',
})
export class ValentineService extends BasePromo {

  private _actionRoutesConfig = {
    [ValentineAction.GET_BONUS]: {
      key: null,
      url: '/profile/account',
      authUrl: ['profile', 'account'],
    },
    [ValentineAction.GO_TO_BONUSES]: {
      key: null,
      url: '/bonuses',
      authUrl: ['bonuses'],
    },
    [ValentineAction.TOURNAMENTS]: {
      key: 'promo-tournaments',
      url: '/tournaments',
      authUrl: ['tournaments'],
    },
    [ValentineAction.GO_TO_DEPOSIT]: {
      key: 'promo-deposit',
      url: '/account/deposit',
      authUrl: ['account', 'deposit'],
    },
    [ValentineAction.PROMO_AUTH]: {
      key: 'promo-auth',
      url: '/promo/xmas',
      authUrl: null,
    },
    [ValentineAction.GAMES]: {
      key: null,
      url: '/games/winter-favorites',
      authUrl: ['games', 'winter-favorites'],
    },
  };

  public eventName = 'valentine';

  public valentineStore =
    useStore<ValentineStoreEntity>(GlobalStoreName.VALENTINE_STORE);

  public store = computed(() => this.valentineStore.storeSignal());

  public tournaments$: Observable<ITournament[]> = this._getTournaments$();

  public getPromo$(): Observable<IValentinePage> {
    return this._fetchPromoData$().pipe(
      tap(() => {
        this._updateSpinderCards();
      }),
      filter(data => !!data[0]),
      map(([promo, lootbox, fs, bonuses]) =>
        this._processPromoData$(promo, lootbox, fs, bonuses)),
      tap(({ bonuses, fs, lootbox, promo }) => {
        this._updateValentineStore(lootbox, fs, bonuses, promo);
      }),
    );
  }

  public readQueryParamsDepositSuccess() {
    this.route.queryParams.pipe(
      filter(({ deposit_success }) => !!deposit_success),
      tap(() => this.cookie.delete(ALREADY_SWIPE_PROMO_STR)),
    ).subscribe();
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory$(): Observable<any> {
    return this.cmsApi.gameList({ category: GameCategory.VALENTINE_DAY})
      .pipe(
        map(list => this.array.toChunks(
          this.games.gameListMapper(
            this.array.checkAndCloneArray(list.data.gameList, this.breakpoint.belowSmallDesktop ? 4 : 5)),
          this.breakpoint.belowSmallDesktop ? 4 : 5)),
      );
  }

  public onCompleteMatch() {
    const {
      promoLootbox,
      welcomeLootbox,
      fs,
      cash,
    } = this.store().currentBonus || {};

    const cashPrize = cash ? `${cash.amount} ${cash.currency}` : undefined;

    if (this.store().isUserCanPartiallyParticipateInPromo) {
      if (welcomeLootbox) {
        this._activateLootbox$(welcomeLootbox.id).pipe(
          switchMap((response) => {
            const fs_count = this.bonuses.resolveBonusAttributes(
              response?.bonuses[0].attributes)?.freespins_count;
            this._updateCurrentPrize(fs_count, cashPrize ?? undefined);
            return this.bonuses.activateFreeSpins(response?.id);
          }),
        ).subscribe();
      } else if (fs) {
        this.bonuses.activateFreeSpins(fs?.id).subscribe(() => {
          this._updateCurrentPrize(fs?.freespins_total, cashPrize ?? undefined);
        });
      }
    }

    if (this.store().isUserCanParticipateInPromo && promoLootbox) {
      this._activateLootbox$(promoLootbox?.id).pipe(
        switchMap((response) => {
          const fs_count = this.bonuses.resolveBonusAttributes(
            response?.bonuses[0].attributes)?.freespins_count;
          this._updateCurrentPrize(fs_count);
          return this.bonuses.activateFreeSpins(response?.id);
        }),
      ).subscribe();
    }
  }

  public async onAction(
    action: ValentineAction,
    bonusCode: string = '',
    successUrl: string = this.window.nativeWindow.location.href + '?deposit_success=true',
  ) {
    const routeConfig = this._actionRoutesConfig[action];
    const { key, url, authUrl } = routeConfig;

    if (action === ValentineAction.GO_TO_DEPOSIT) {
      await this._handleActionRoute(
        key,
        url,
        authUrl,
        { successUrl, bonusCode },
        { queryParams: { successUrl, bonusCode } },
      );
    } else {
      await this._handleActionRoute(key, url, authUrl);
    }
  }

  private async _handleActionRoute(
    key: string, url: string, authUrl: string[], urlData?: any, extras?: any) {
    const urlParams = { key, url, authUrl, urlData, extras };
    if (!key) {
      await this.router.navigateByUrl(url);
      return;
    }
    if (!this.user.auth) {
      this.local.addUrl(urlParams.key, urlParams.url, urlParams.urlData);
      await this.user.authUser();
    } else {
      await this.router.navigate(urlParams.authUrl, urlParams.extras);
    }
  }

  private _getTournaments$(): Observable<any> {
    return this.tournaments.list({ with_game_list: 1 }).pipe(
      filter((tournaments) => !!tournaments),
      map(tournaments => tournaments.now.filter(tournament => {
        return tournament.slug.includes('valentine');
      })),
    );
  }

  private _updateSpinderCards() {
    this.valentineStore.storeSignal.update(store => {
      return {
        ...store,
        spinderCards: this._getStackCardsByDay(),
      };
    });
  }

  private _updateCurrentPrize(fs_count: number, cash?: string) {
    this.valentineStore.storeSignal.update(store => ({
      ...store,
      currentPrize: {
        cash,
        fs_count,
      },
    }));
    this.cookie.set(ALREADY_SWIPE_PROMO_STR, '1');
  }

  private _activateLootbox$(id: number): Observable<any> {
    return this.lootboxService.activateLootBox(id);
  }

  private _fetchPromoData$(): Observable<[
    IValentinePage[], any[], any[], any[]]> {
    return combineLatest([
      this.page.item({
        slug: this.eventName
      }),
      this.user.auth ? this.lootboxService.loadUserLootBox() : of([]),
      this.user.auth ? this.bonuses.freeSpinsList() : of([]),
      this.user.auth ? this.bonuses.bonusList() : of([]),
    ]);
  }

  private _processPromoData$(
    promo: IValentinePage[],
    lootbox: any[],
    fs: any[],
    bonuses: any[]): any {
    return {
      ...promo?.map(promoItem => ({
        ...promoItem,
        HowWorksSteps: [...Object.values(promoItem?.HowWorksSteps)],
        unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
        publishAt: this.time.toLocalDate(promoItem?.publishAt),
      }))[0],
      fs,
      lootbox,
      bonuses,
    };
  };

  private _updateValentineStore(
    lootbox: any, fsBonuses: any, bonuses: any, promo: IValentinePage): void {

    const cash = this._getFilteredCashWelcomeBonuses(bonuses);
    const fs = this._getFilteredFreespinsWelcomeBonuses(fsBonuses);
    const welcomeLootbox = this._getFilteredWelcomeLootbox(lootbox);
    const promoLootbox = this._getFilteredPromoLootbox(lootbox);

    const allWelcomePackClaimed = this.group.isExistGroup(
      BonusesStatuses.ALL_WELCOME_OFFERS_USED) || this.group.isExistGroup(
      BonusesStatuses.ALL_SPECIAL_WELCOME_USED);

    const isUserAlreadyActivatedPromoLootbox =
      this._checkActivatedTodayLootbox(lootbox);

    const isUserCanParticipateInPromo =
      Boolean(allWelcomePackClaimed && promoLootbox);

    const isUserCanPartiallyParticipateInPromo =
      Boolean((cash || fs || welcomeLootbox) && !promoLootbox);

    const weeklyCompletedBonusesCount = allWelcomePackClaimed ?
      this._countWeeklyBonus() : null;

    const isWeeklyMissionsCompleted =
      weeklyCompletedBonusesCount === this.store().weeklyCompletedBonusesLimit;

    this.valentineStore.storeSignal.update(store => {
      return {
        ...store,
        currentBonus:
          fs && cash || welcomeLootbox && cash || fs || promoLootbox ? {
            cash,
            fs,
            welcomeLootbox,
            promoLootbox,
          } : null,
        isUserCanParticipateInPromo,
        isUserCanPartiallyParticipateInPromo,
        isUserAlreadyActivatedPromoLootbox,
        weeklyCompletedBonusesCount,
        isWeeklyMissionsCompleted,
        weeklyHeartBonuses: store.weeklyHeartBonuses.map((bonus, index) => {
          if (weeklyCompletedBonusesCount !== null &&
            index <= weeklyCompletedBonusesCount) {
            return { ...bonus, default: false, active: true };
          }
          return bonus;
        }),
      };
    });
  }

  /**
   * Get stack cards images by day and idx
   * @private
   */
  public _getStackCardsByDay(): { img: string }[] {
    const cards = [];
    const dayIndex = new Date().getDay();
    for (let i = 0; i < 5; i++) {
      cards.push({
        img: `/assets/img/promo/valentine/cards/${dayIndex}/${i + 1}.png`,
      });
    }
    return cards;
  }

  private _getFilteredPromoLootbox(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(lootbox =>
      lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredWelcomeLootbox(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(lootbox => lootbox.isWelcome &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredFreespinsWelcomeBonuses(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.ISSUED &&
      bonus.isWelcome && !this.cookie.check(ALREADY_SWIPE_PROMO_STR))
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _getFilteredCashWelcomeBonuses(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(bonus =>
      bonus.stage === BonusStage.HANDLE_BETS &&
      bonus.isWelcome && !this.cookie.check(ALREADY_SWIPE_PROMO_STR))
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _checkActivatedTodayLootbox(arrayTcCheck: any[]): boolean {
    return arrayTcCheck?.some(lootbox =>
      lootbox.stage === BonusStage.ACTIVATED &&
      lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      this.time.isTodayDate(lootbox.created_at));
  }

  private _countWeeklyBonus(): number {
    switch (true) {
      case this.group.isExistGroup('ID958'):
        return 0;
      case this.group.isExistGroup('ID959'):
        return 1;
      case this.group.isExistGroup('ID960'):
        return 2;
      case this.group.isExistGroup('ID961'):
        return 3;
      case this.group.isExistGroup('ID991'):
        return 4;
      case this.group.isExistGroup('ID992'):
        return 5;
      default:
        return null;
    }
  }

  checkActivatedBonuses(data: WelcomePromoModal): Observable<any> {
    return this.bonuses.spinderActivatedBonusesFs$.pipe(
      first(),
      tap(async (isShouldOpened) => {
        if (isShouldOpened) {
          const { ValentineWelcomeModalComponent } = await import(
            '../../../core/modal-v2/components/lazy/valentine-welcome-modal/valentine-welcome-modal.component'
            );
          await this.modals.openLazy(ValentineWelcomeModalComponent, { data, template: 'CLEAR' });
          this.cookie.set('welcome-modal-promo', 'true', 1, '/');
        }
      }),
    );
  }

  openHuntModal(type: EventType): void {
  }

}
