import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslationService } from '../shared/translation/translation.service';

/**
 * Error messages by keys
 */
export const ERROR_MESSAGES = {
  required: 'error.blank',
  required_true: 'error.accepted',
  required_country: 'error.select-country',
  pattern: 'error.invalid-format',
  password_pattern: 'error.short-password',
  must_match: 'error.passwords-match',
  eighteen_years: 'error.young',
  contain_spaces: 'error.spaces',
  use_visa: 'error.use-visa',
  use_mastercard: 'error.use-mastercard',
  minlength: 'error.required-min-length',
  maxlength: 'error.required-max-length',
  yearInPast: 'error.year-in-past',
};

/**
 * Error messages for specific masks
 */
export const SPECIFIC_MASK = {
  'S0S 0S0': 't.postal-code-error-for-ca'
};

@Injectable({
  providedIn: 'root'
})
export class FormsErrorHandlerService {

  constructor(
    private _translate: TranslationService
  ) { }

  /**
   * Apply form errors with messages
   * And return object with errors for each key
   *
   * @param form
   * @param ssResponse
   * @param touch
   */
  public applyFormErrors(form: UntypedFormGroup, ssResponse: any = null, touch = false): any {

    const formErrors = {};

    ssResponse = ssResponse || {errors: {}};

    Object.keys(form.controls).forEach(controlKey => {
      const control = form.get(controlKey);

      let errors = {
        ...control.errors || {},
        ...(ssResponse.errors && ssResponse.errors[controlKey] || {})
      };

      const errorKeys = Object.keys(errors);

      if (errorKeys.length) {
        errorKeys.forEach(errorKey => {
          errors[errorKey] = this.getErrorMessageByKey(errorKey) || errors[errorKey];
        });
      } else {
        errors = null;
      }

      control.setErrors(errors);

      formErrors[controlKey] = this.errors(control);

      if (touch) {
        control.markAsTouched();
      }
    });

    /**
     * Uses for get errors which key not match control key
     */
    Object.keys(ssResponse).forEach(errorKey => {
      Object.keys(ssResponse[errorKey]).forEach(key => {
        if (!formErrors[key]) {
          formErrors[key] = Object.keys(ssResponse[errorKey][key]).map(error => ssResponse[errorKey][key][error]);
        }
      });
    });

    return formErrors;
  }

  /**
   * Returns array of error messages if exists for provided control
   *
   * @param control
   */
  public errors(control: AbstractControl): Array<string> {
    return Object.keys(control.errors || {}).map(key => {
      if (key === 'min' || key === 'max') {
        return `${key} ${control.errors[key][key]}`;
      } else if (key === 'minlength' || key === 'maxlength') {
        return this._translate.translate('error.required-length', {length: control.errors[key].requiredLength});
      } else if (key === 'mask') {
        const requiredMask = control.errors[key].requiredMask;
        return SPECIFIC_MASK[requiredMask] || ERROR_MESSAGES[key];
      } else {
        return this.getErrorMessageByKey(key) || control.errors[key];
      }
    });
  }

  /**
   * Returns error message for provided key
   *
   * @param key
   */
  public getErrorMessageByKey(key: string): string {
    return ERROR_MESSAGES[key] || '';
  }

  /**
   * Convert SS error response to array of errors
   *
   * @param error
   */
  public ssBackendErrorsToArray(error: any): Array<string> {
    const errorList = [];

    /**
     Returning an error when withdrawing funds if the user has not yet used the account
     */
    if (error && error.errors && error.errors.amount && error.errors.amount.not_used_account) {
      errorList.push(error.errors.amount.not_used_account);
    }

    if (error) {
      Object.keys(error).forEach(key => {
        const type = typeof error[key];

        if (type === 'object') {
          errorList.push(...this.ssBackendErrorsToArray(error[key]));
        } else if (type === 'string') {
          errorList.push(error[key]);
        }
      });
    }

    return errorList;
  }
}
