import { Injectable } from '@angular/core';
import { SsApiService } from '../api/ss-api.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { BonusCodeStatus, BonusStage, BonusStageLabel, BonusStageLabelKeys, BonusType } from './data/user-bonuses.data';
import { CommonDataService } from '../common-data.service';
import { GamesService } from '../games/games.service';
import { FiltersService } from '../filters.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { TimeService } from '../time.service';
import { isNullOrUndefined } from '../../helpers/utils';
import { UserService } from './user.service';
import { GroupsService } from '../groups.service';
import { OffersService } from '../offers.service';
import { BONUS_TYPES } from '../../../page/bonuses/bonuses.component';
import { valentineStore } from '../../store/valentine.store';

export const DEFAULT_PATH = '/assets/img/bonuses';

export const DEFAULT_PATH_REDESIGN = '/assets/img/bonuses-redesign';

export const DEFAULT_IMAGE_PATH = '/assets/img/bonuses-redesign/bonus-account.png';
export const LOOTBOX_IMAGE_PATH = '/assets/img/bonuses/lootbox.png';

const ListIds = {
  half_empty_glass_first: `${ DEFAULT_PATH_REDESIGN }/half_empty_glass_first_deposit.png`,
  half_empty_glass_second: `${ DEFAULT_PATH_REDESIGN }/half_empty_glass_second_deposit.png`,
  half_empty_glass_third: `${ DEFAULT_PATH_REDESIGN }/half_empty_glass_third_deposit.png`,
  half_full_glass_first: `${ DEFAULT_PATH_REDESIGN }/half_full_glass_first_deposit.png`,
  half_full_glass_second: `${ DEFAULT_PATH_REDESIGN }/half_full_glass_second_deposit.png`,
  half_full_glass_third: `${ DEFAULT_PATH_REDESIGN }/half_full_glass_third_deposit.png`,
  highroller_first: `${ DEFAULT_PATH_REDESIGN }/highroller_first_deposit.png`,
  highroller_second: `${ DEFAULT_PATH_REDESIGN }/highroller_second_deposit.png`,
  highroller_third: `${ DEFAULT_PATH_REDESIGN }/highroller_third_deposit.png`,
  friday_bonus: `${ DEFAULT_PATH_REDESIGN }/friday_bonus.png`,

  /**
   * Easter promo bonuses ids
   */
  scheduled_fs: `${ DEFAULT_PATH_REDESIGN }/easter-1.png`,
  freespins_group: `${ DEFAULT_PATH_REDESIGN }/easter-2.png`,
  deposit_percent: `${ DEFAULT_PATH_REDESIGN }/easter-3.png`,

  /**
   * Bonuses program v2
   */
  first_wlcm: `${ DEFAULT_PATH_REDESIGN }/half_empty_glass_first_deposit.png`,
  second_wlcm: `${ DEFAULT_PATH_REDESIGN }/half_empty_glass_second_deposit.png`,
  third_wlcm: `${ DEFAULT_PATH_REDESIGN }/half_empty_glass_third_deposit.png`,
};

export const specificBonusListTitle = {
  /**
   * For sofort bonus
   */
  'Sofort Deposit Spins': `${ DEFAULT_PATH }/bonus_img_sofort.png`
};

/**
 * Bonus pack types
 */
export const BONUS_PACKS = {
  STARTER: 'starter',
  HIGHROLLER: 'highroller',
};

export const GROUP_ALL_BONUSES_USED = 'ID341';

@Injectable({
  providedIn: 'root'
})
export class UserBonusesService {

  public bonuses$: Observable<any>;

  /**
   * Bonus code activation response success messages
   */
  private _couponSuccessMessages = {
    [BonusCodeStatus.SUCCESSFULLY_ACTIVATED]: 't.bonus-success-activated'
  };

  /**
   * Bonus code activation response error messages
   */
  private _couponErrorMessages = {
    [BonusCodeStatus.ALREADY_ACTIVATED]: 't.bonus-already-activated',
    [BonusCodeStatus.FAILED_TO_ACTIVATE]: 't.failed-active-bonus'
  };

  /**
   * Variable that keep boolean if user activated all welcome bonuses
   */
  private _isAllWelcomeBonusesActivated = false;

  /**
   * Variable that keep boolean if user have avaliable friday bonus
   */
  private _isFridayBonusTaken = false;

  private _isNoHighRoller = false;

  /**
   * Deposit bonus list
   * @private
   */
  private _depositBonusList$ = this._user.auth$.pipe(
    switchMap(auth => {
      return auth ? this.depositBonusList() : of([]);
    })
  );

  /**
   * ALL CMS BONUS LIST
   * @private
   */
  private _allCmsBonusList$: Observable<any> = this._offers.list(
    {category_slug: 'samurai-welcome-bonus'}
  );

  /**
   * CMS bonus list. Get bonuses from CMS and separate by bonus pack types
   * @private
   */
  private _cmsBonusList$: Observable<any> = this._offers.list(
    {category_slug: 'samurai-welcome-bonus'}).pipe(
    filter(bonuses => bonuses && bonuses.length),
    map((list: any[]) => {
      this._isNoHighRoller = list.every((e) => e.cmsItemName.toLowerCase().includes('no highroller'));
      const highrollerBonuses = this._separateBonuses(list, true);
      const starterBonuses = this._separateBonuses(list, false);
      return {
        [BONUS_PACKS.STARTER]: starterBonuses,
        [BONUS_PACKS.HIGHROLLER]: highrollerBonuses,
      };
    })
  );

  /**
   * Is all welcome bonus used
   */
  public isAllBonusUsed = false;

  public bonusPacks = BONUS_PACKS;

  /**
   * Replay subject for loyalty freespins bonuses
   * @private
   */
  private _loyaltyFreeSpinsBonuses$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public spinderActivatedBonusesFs$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private _api: SsApiService,
    private _data: CommonDataService,
    private _games: GamesService,
    private _filters: FiltersService,
    private _toastMessage: ToastMessageService,
    private _time: TimeService,
    private _user: UserService,
    private _group: GroupsService,
    private _offers: OffersService,
  ) {
  }

  get allCmsBonusList$() {
    return this._allCmsBonusList$;
  }

  get Stage() {
    return BonusStage;
  }

  get Type() {
    return BonusType;
  }

  /**
   * Return true if all welcome bonuses activated
   */
  get isAllWelcomeBonusesActivated() {
    return this._isAllWelcomeBonusesActivated;
  }

  /**
   * Return true if user have friday bonus
   */
  get isFridayBonusTaken() {
    return this._isFridayBonusTaken;
  }

  get loyaltyFreeSpinsBonuses$() {
    return this._loyaltyFreeSpinsBonuses$;
  }

  /**
   * Returns list of player money bonuses
   */
  bonusList(): Observable<any> {
    return this._api.playerBonuses().pipe(
      catchError(error => of([])),
      map(list => this.mapPlayerBonuses(this.mapBonuses(list))),
    );
  }

  /**
   * Returns list of player free spins bonuses
   */
  freeSpinsList(): Observable<any> {
    let games = new Set();
    let bonuses = [];
    return this._api.playerFreeSpins().pipe(
      catchError(error => of([])),
      map(list => this._mapFreeSpins(this.mapBonuses(list))),
      tap(list => {
        games = new Set();
        list.forEach(bonus => bonus.games.forEach(game => games.add(game)));
        bonuses = list;
      }),
      switchMap(() => this._games.list({
        'external_id[]': [...games],
        limit: games.size >= 100 ? 100 : games.size
      })),
      filter(response => !!response),
      map(gameResponse => {
        const gamesByExternalId = this._filters.valueAsKeyObject('externalId', gameResponse.gameList || []);
        return bonuses.map(bonus => ({
          ...bonus,
          games: (bonus.games || [])
            .map(gameId => gamesByExternalId[gameId] || {})
            .filter(e => Object.keys(e).length)
        }));
      })
    );
  }

  updateBonuses() {
    return this.bonuses$ = combineLatest([
      this.freeSpinsList(),
      this.bonusList(),
    ]).pipe(
      map(([fs, bonuses]) => [...fs, ...bonuses]),
      map((list) => list.filter((bonus) => bonus.active)),
      map(list => list.map(item => {
        return {
          ...item,
          endAt: !isNullOrUndefined(item.valid_until) ? this._time.timeDiff(new Date(item.valid_until)) : null,
          imgSrc: this.resolveBonusImage(item),
          frontend_label: this.resolveBonusLabel(item),
        };
      })),
      map(list => {
        return list.map(b => {
          const gamesInfo = b?.games_info?.length === 1 ? b?.games_info : b?.games_info?.slice(0, 2);
          return {
            ...b,
            games$: gamesInfo ? this._games.list({'external_id[]': gamesInfo?.map(g => g.identifier)}).pipe(
              map(data => data?.gameList),
              shareReplay()
            ) : of([])
          };
        });
      }),
      map((list: any[]) => {
        return {
          [this.Stage.ISSUED]: list.filter(
            (e) => e.stage === this.Stage.ISSUED || e.stage === this.Stage.HANDLE_BETS
          ),
          [this.Stage.CANCELED]: list.filter(
            (e) => e.stage === this.Stage.CANCELED,
          ),
          [this.Stage.ACTIVATED]: list.filter(
            (e) => e.stage === this.Stage.ACTIVATED
          ),
          [this.Stage.EXPIRED]: list.filter(
            (e) => e.stage === this.Stage.EXPIRED
          ),
          [this.Stage.VALENTINE_FS]: list.filter(
            (e) => (e.stage === this.Stage.ACTIVATED || e.stage === this.Stage.ISSUED) &&
              (e.type === this.Type.FREE_SPINS) && (e.title.trim().toLowerCase().includes(valentineStore.valentineBonusStr) &&
                this._time.areSameUTCDates(e.created_at)
              )
          ),
        };
      }),
      tap((list) => {
        this.spinderActivatedBonusesFs$.next(list[this.Stage.VALENTINE_FS]);
      }),
    );
  }

  /**
   * Send request on update bonus settings
   */
  changeIssues(state: boolean) {
    return this._api.playerUpdateBonusSettings({can_issue: state});
  }

  /**
   * Activate bonus by id
   *
   * @param id
   */
  activateBonus(id: string): Observable<any> {
    return this._api.playerBonusesActivation(id);
  }

  /**
   * Cancel bonus by id
   *
   * @param id
   */
  cancelBonus(id: string): Observable<any> {
    return this._api.playerBonusesCanceling(id);
  }

  /**
   * Activate free spins bonus by id
   *
   * @param id
   */
  activateFreeSpins(id: string | number): Observable<any> {
    return this._api.playerFreeSpinsActivation(id);
  }

  /**
   * Cancel bonus by id
   *
   * @param id
   */
  cancelFreeSpins(id: string | number): Observable<any> {
    return this._api.playerFreeSpinsCanceling(id);
  }


  /**
   * Coupon code activation
   *
   * @param code
   */
  public activateCoupon(code: string): Observable<any> {
    return this._api.bonusesCoupon({coupon_code: (code || '').toUpperCase()});
  }


  /**
   * Show toast message depend on coupon code activation response
   *
   * @param response
   * @private
   */
  public resolveCouponResponseMessage(response) {
    const status = response.status;

    if (this._couponSuccessMessages[status]) {
      this._toastMessage.success(this._couponSuccessMessages[status]);
    } else if (this._couponErrorMessages[status]) {
      this._toastMessage.error(this._couponErrorMessages[status]);
    } else {
      this._toastMessage.error('t.undefined-error');
    }
  }

  /**
   * Prepare bonus list for using in frontend
   *
   * @param list
   * @private
   */
  public mapBonuses(list: Array<any>): Array<any> {
    return list.map(bonus => ({
      ...bonus,
      isWelcome: this.isWelcomeBonus(bonus.title),
      title: this.mapBonusTitle(bonus.title),
      active: ![BonusStage.LOST, BonusStage.CANCELED, BonusStage.PLAYED].includes(bonus.stage),
      stage_label: BonusStageLabel[bonus.stage] || bonus.stage,
      created_at: bonus.created_at ? new Date(bonus.created_at) : null,
      activatable_until: bonus.activatable_until ? new Date(bonus.activatable_until) : null,
      valid_until: bonus.valid_until ? new Date(bonus.valid_until) : null,
      isFridayBonus: bonus?.group_key === 'friday_bonus',
      type: 'money',
    }));
  }

  /**
   * Compare bonus list and return active bonus
   * @param bonusListCMS
   * @param bonusListSS
   * @param bonusPack
   * @returns
   */
  public findActiveBonus(bonusListCMS: any[], bonusListSS: any[], bonusPack?: string) {
    return bonusListCMS.find(cmsBonus => {
      return bonusListSS.find(ssBonus => {
        if (ssBonus?.bonus?.id?.includes(bonusPack !== BONUS_PACKS.STARTER ? cmsBonus.BOIdentifier : cmsBonus.BOIdentifierStarter) ||
          ssBonus?.freespins?.id?.includes(bonusPack !== BONUS_PACKS.STARTER ? cmsBonus.BOIdentifier : cmsBonus.BOIdentifierStarter)) {
          cmsBonus.bonus_amount_max = ssBonus?.bonus?.attributes?.bonus_amount_max;
          cmsBonus.bonus_amount_percent = ssBonus?.bonus?.attributes?.bonus_amount_percent;
          return ssBonus;
        } else {
          return null;
        }
      });
    });
  }

  /**
   * Prepare player bonuses for using if frontend
   *
   * @param list
   * @private
   */
  public mapPlayerBonuses(list: Array<any>): Array<any> {
    return list.map(bonus => ({
      ...bonus,
      currency_symbol: this._data.currencySymbol(bonus.currency),
      amount: this._data.subunitsToUnits(bonus.amount_cents, bonus.currency),
      amount_wager_requirements: this._data.subunitsToUnits(bonus.amount_wager_requirement_cents, bonus.currency),
      amount_wager: this._data.subunitsToUnits(bonus.amount_wager, bonus.currency),
      wager: (bonus.amount_wager_requirement_cents ?
        (bonus.amount_wager_cents / bonus.amount_wager_requirement_cents * 100) :
        100)
    }));
  }

  /**
   * Prepare free spins bonuses for using in frontend
   *
   * @param list
   * @private
   */
  public _mapFreeSpins(list: Array<any>): Array<any> {
    this._loyaltyFreeSpinsBonuses$.next(list);
    return list.map(bonus => ({
      ...bonus,
      freespins_performed: bonus.freespins_performed || 0,
      type: 'freespins'
    }));
  }

  /**
   * Bonus deposit code activation
   *
   * @param code
   */
  public activateDepositBonusCode(code): Observable<any> {
    return this._api.playerSetBonusCode({deposit_bonus_code: (code || '').toUpperCase()});
  }

  /**
   * Bonus deposit code clear
   *
   */
  public deleteDepositBonusCode(): Observable<any> {
    return this._api.playerClearBonusCode();
  }

  /**
   * Returns list of bonuses that will be activated on next deposit
   */
  depositBonusList(): Observable<any> {
    return this._api.bonusesDeposit().pipe(
      map(list => {
        return (list || []).map(bonus => {

          const preparedBonus = {};

          bonus.bonuses.forEach(bonusPart => {
            preparedBonus[bonusPart.type] = {
              title: this.mapBonusTitle(bonusPart.title),
              id: bonus.id,
              deposit: true,
              attributes: this.resolveBonusAttributes(bonusPart.attributes),
              conditions: this.resolveBonusAttributes(bonusPart.conditions),
              imageUrl: this._resolveImageUrl(ListIds, bonus),
              result_bonus: bonusPart.result_bonus ? this.resolveBonusAttributes(bonusPart.result_bonus) : null,
              type: BONUS_TYPES.DEPOSIT,
            };
          });

          return preparedBonus;
        });
      }),
      catchError(error => of([])),
    );
  }

  private _resolveImageUrl(listIds: object, bonus: any) {

    let key: string;

    key = Object.keys(listIds).find((id: string) => bonus.id.includes(id));

    if (key) {
      return listIds[key];
    } else {
      return DEFAULT_IMAGE_PATH;
    }

  }

  /**
   * Returns object from array of attributes
   *
   * @private
   * @param list
   */
  public resolveBonusAttributes(list: Array<any>) {
    const attributes: any = {};

    list.forEach(attribute => {
      if (attribute.field === 'bonus_amount' && attribute.type === 'max') {
        attributes.bonus_amount_max = this._resolveAmountList(attribute.value);
      } else if (attribute.field === 'bonus_amount' && attribute.value.percent) {
        attributes.bonus_amount_percent = attribute.value.percent;
      } else if (attribute.field === 'amount' && attribute.type === 'min') {
        attributes.amount_min = this._resolveAmountList(attribute.value);
      } else if (attribute.field === 'amount' && attribute.type === 'max') {
        attributes.amount_max = this._resolveAmountList(attribute.value);
      } else if (attribute.field === 'wager') {
        attributes.wager = attribute.value;
      } else if (attribute.field === 'max_win') {
        attributes.max_win = this._resolveAmountList(attribute.value);
      } else {
        attributes[attribute.field] = attribute.value;
      }
    });

    return attributes;
  }

  /**
   * Convert amounts list from backend to object where keys is currency and values is converted amount
   *
   * @param list
   * @private
   */
  private _resolveAmountList(list: Array<any>) {
    const amounts = {};

    list.forEach(amount => {
      amounts[amount.currency] = this._data.subunitsToUnits(amount.amount_cents, amount.currency);
    });

    return amounts;
  }

  /**
   * Cut analytics tags from title string.
   * Example input: [WLC*]Highroller First Deposit 50%, output: Highroller First Deposit 50%
   * @param title
   */
  public mapBonusTitle(title: string): string {
    return (title || '')
      .replace(/\[(.*?)]/g, '')
      .replace(/\[(.*?)}/g, '')
      .replace(/\{(.*?)]/g, '')
      .replace(/\{(.*?)}/g, '')
      .replace(/_/g, ' ')
      .replace('landing', '');
  }

  public resolveBonusImage(bonusData) {
    let image: string;

    if (bonusData?.bonus?.imageUrl) {
      image = bonusData?.bonus?.imageUrl;
    } else if (bonusData?.games?.[0]?.imgSrc) {
      const special =  bonusData.isSpecificBonus && Object.keys(specificBonusListTitle).find(title => bonusData.title.trim().includes(title));
      image = special ? specificBonusListTitle[special] : bonusData?.games?.[0]?.imgSrc;
    } else if (bonusData?.jackpot) {
      if (bonusData.jackpot.jackpot_level_external_code === 'level_1') {
        image = '/assets/img/jackpot/levels/1.png';
      }
      if (bonusData.jackpot.jackpot_level_external_code === 'level_2') {
        image = '/assets/img/jackpot/levels/2.png';
      }
      if (bonusData.jackpot.jackpot_level_external_code === 'level_3') {
        image = '/assets/img/jackpot/levels/3.png';
      }
    } else {
      image = DEFAULT_IMAGE_PATH;
    }

    return image;
  }

  /**
   * Get merged bonuses from CMS and SS
   * @private
   */
  public getMergedBonuses$(): Observable<any> {
    return combineLatest([this._cmsBonusList$, this._depositBonusList$]).pipe(
      map(([bonusListCms, bonusListSS]) => {
        return {
          [BONUS_PACKS.STARTER]: this.markActiveBonus(bonusListCms[BONUS_PACKS.STARTER], bonusListSS, BONUS_PACKS.STARTER),
          [BONUS_PACKS.HIGHROLLER]: this.markActiveBonus(bonusListCms[BONUS_PACKS.HIGHROLLER], bonusListSS, BONUS_PACKS.HIGHROLLER),
        };
      }),
      map(list => {
        return {
          [BONUS_PACKS.STARTER]: this._markActiveBonusIdx(list, BONUS_PACKS.STARTER),
          [BONUS_PACKS.HIGHROLLER]: this._markActiveBonusIdx(list, BONUS_PACKS.HIGHROLLER),
        };
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  /**
   * Mark active bonus and resolve active bonus image
   * @param bonusListCMS
   * @param bonusListSS
   * @param bonusPack
   */
  public markActiveBonus(bonusListCMS: any[], bonusListSS: any[], bonusPack?: string) {
    if (this._user.auth) {
      if (this._group.isExistGroup(GROUP_ALL_BONUSES_USED)) {
        this.isAllBonusUsed = true;
        bonusListCMS.forEach(e => {
          e.used = true;
        });
        this._isAllWelcomeBonusesActivated = true;
      } else {
        bonusListCMS.forEach(e => e.active = false);
        let activeBonus = this.findActiveBonus(bonusListCMS, bonusListSS, bonusPack);
        if (!activeBonus) {
          activeBonus = bonusListCMS[0];
        }
        const activeBonusIndex = bonusListCMS.findIndex(e => e.id === activeBonus.id);
        bonusListCMS.forEach((e, i) => {
          e.stage = 'issued';
          e.type = BONUS_TYPES.WELCOME;
          if (i < activeBonusIndex) {
            e.used = true;
          }
        });
        activeBonus.active = true;
      }
      return bonusListCMS;
    } else {
      bonusListCMS[0].active = true;
      return bonusListCMS;
    }
  }

  /**
   * Mark active bonus idx
   * @param list
   * @param bonusPackType
   * @private
   */
  private _markActiveBonusIdx(list: object, bonusPackType: string): any[] {
    return list[bonusPackType].map(item => {
      return {
        ...item,
        activeBonusIdx: list[bonusPackType].findIndex(e => e.active),
      };
    });
  }

  /**
   * Separate bonuses data on 2 lists
   * @param list
   * @param isHighroller
   * @private
   */
  private _separateBonuses(list: any[], isHighroller: boolean): any[] {
    return list.map(item => {
      const filteredItem: any = {...item};
      for (const [key, value] of Object.entries(item)) {
        const starterIndex = key.toLowerCase().indexOf(BONUS_PACKS.STARTER);
        if (!isHighroller && starterIndex !== -1) {
          filteredItem[key.slice(0, starterIndex)] = value;
        }
      }
      return filteredItem;
    });
  }

  /**
   * Resolve bonus label for bonus
   * @param bonus
   * @private
   */
  public resolveBonusLabel(bonus) {
    return BonusStageLabelKeys[bonus.stage];
  }

  /**
   * Check if friday bonus is available and sets result in _isFridayBonusTaken
   * @param
   * @public
   */
  public checkAvabilityFridayBonus(): Observable<void> {
    if (this._user.auth) {
      return forkJoin([
        this.bonusList(),
        this.freeSpinsList().pipe(take(1)),
      ]).pipe(
        first(),
        map(([bonuses, freespins]) => [...bonuses, ...freespins]),
        filter((data) => !!data),
        map((data) => {
          const fridayBonuses = data.filter((e) => e.isFridayBonus);

          this._isFridayBonusTaken = fridayBonuses.length
            ? data.some((e) => e.active)
            : true;

          return undefined;
        }),
      );
    } else {
      this._isFridayBonusTaken = true;
      return of(undefined);
    }
  }
  public checkDifferenceMoreTwoDays() {
    if (!this._user.info.created_at) {
      return true;
    } else {
      const today = new Date();
      // ios problem handling
      const dateString = this._user.info.created_at.replace(" ", "T").replace('UTC', '').trim() + 'Z';
      const dateRegister = new Date(dateString);
      const millisecondsDiff = today.getTime() - dateRegister.getTime();
      return Math.round(millisecondsDiff / (24 * 60 * 60 * 60)) >= 2;
    }
  }

  /**
   * Checks if title contains WCM which indicates that it's a welcome bonus
   *
   * @param list
   * @private
   */
  public isWelcomeBonus(title: string) {
    return ['WLC', 'WCM', 'AFF'].some(key => title.toUpperCase().includes(key));
  }

}
