import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-form-checkbox-toggle',
  templateUrl: './form-checkbox-toggle.component.html',
  styleUrls: ['./form-checkbox-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxToggleComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgClass,
  ],
})

export class FormCheckboxToggleComponent implements OnInit, ControlValueAccessor {

  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public checked: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};

  public writeValue(value: any): void {
    if (this.checked !== Boolean(value)) {
      this.checked = value;
      this.onChange(value);
      this.change.next(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public toggleChecked(): void {
    this.writeValue(!this.checked);
  }
}
