import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { UserService } from './user.service';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { isNullOrUndefined } from '../../helpers/utils';
import { ModalService } from '../../modal-v2/modal.service';
import { Tab } from '../../modal-v2/components/lazy/terms-acceptance-form-modal/terms-acceptance-form-modal.component';
import { SsPaymentsV2Service } from '../../vendor/ss-payments-v2/ss-payment.service';

export interface FieldDescriptor {
  field: string;
  once_set: boolean;
  type: string;
  inclusion?: {
    in?: any[]
  };
}

export interface FieldsResponse {
  contexts: {
    [key: string]: string[]
  };
  fields: FieldDescriptor[];
}

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  /**
   * List of user attributes that need to be filled for specific action
   */
  private _requiredFields$: Observable<FieldsResponse> = this._api.infoPlayerFields().pipe(
    shareReplay(1)
  );

  private _user: UserService;

  private _missingAuthModal: any;

  constructor(
    private _api: SsApiService,
    private _env: EnvironmentService,
    private _modals: ModalService,
    private _ssPayments: SsPaymentsV2Service
  ) {
  }

  /**
   * For avoiding circular dependency
   *
   * @param service
   */
  set userService(service: UserService) {
    this._user = service;
  }

  /**
   * Returns list of attribute names that not filled by user but required for deposit
   *
   * @param userInfo
   * @param context
   */
  missingAttributesFor(context: string): Observable<FieldDescriptor[]> {
    if (context) {
      return this._requiredFields$.pipe(
        first(),
        map(
          (fieldsConfig: FieldsResponse) => fieldsConfig.fields.filter(
            field => fieldsConfig?.contexts[context]?.includes(field.field)
              && (isNullOrUndefined(this._user.info[field.field]) || this._user.info[field.field] === ''),
          ),
        ),
      );
    }
  }

  /**
   * Show modal window if user has missed auth fields
   * @param userInfo
   */
  public async checkMissingAuthFields(userInfo) {
    if (userInfo.auth_fields_missed && userInfo.auth_fields_missed.length && !this._missingAuthModal) {
      /**
       * If user missing only terms acceptance -> show terms acceptance modal
       */
      if (userInfo.auth_fields_missed.length === 1 && userInfo.auth_fields_missed.includes('terms_acceptance')) {
        const component = await import('../../modal-v2/components/lazy/terms-acceptance-form-modal/terms-acceptance-form-modal.component');
        this._missingAuthModal = await this._modals.openLazy(component?.TermsAcceptanceFormModalComponent, {
          disableOverlayClosing: true,
          template: 'NO_CLOSE',
          data: {
            tab: Tab.POLICY,
            user: this._user,
            userInfo: this,
          },
        });
        this._missingAuthModal?.onResult().subscribe(() => this._missingAuthModal = null);

        /**
         * Else -> show AuthFieldMissed modal (email, currency, terms)
         */
      } else {
        const component = await import('../../modal-v2/components/lazy/auth-fields-missed-form-modal/auth-fields-missed-form-modal.component');
        this._missingAuthModal = await this._modals.openLazy(component?.AuthFieldsMissedFormModalComponent, {
          disableOverlayClosing: true,
          template: 'BORDER_IMAGE',
        });
        this._missingAuthModal?.onResult().subscribe(() => this._missingAuthModal = null);
      }
    }
  }

  /**
   * Update player info
   *
   * @param data
   * @param context
   */
  updatePlayerInfo(data: object, context?: string): Observable<any> {
    return this._api.patchPlayer({
      context,
      player: data
    }).pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Set new password for user
   *
   * @param data
   */
  changePassword(data: object): Observable<any> {
    return this._api.putUsers({
      user: data
    });
  }

  /**
   * Confirm user account
   *
   * @param token
   */
  userConfirmation(token: string): Observable<any> {
    return this._api.usersConfirmation({
      confirmation_token: token
    });
  }

  /**
   * Auth provider confirmation (when sign up using social networks)
   *
   * @param token
   */
  authProviderConfirmation(token: string): Observable<any> {
    return this._api.authProvidersConfirmation({
      confirmation_token: token
    });
  }

  requiredFields(): Observable<FieldDescriptor[]> {
    return this._requiredFields$.pipe(
      map((fieldsConfig: FieldsResponse) => fieldsConfig.fields)
    );
  }

  /**
   * Set user missed auth fields (when sign up using social networks)
   *
   * @param data
   */
  setMissedAuthFields(data: object): Observable<any> {
    return this._api.authProvidersUpdateDetails({
      user: data
    });
  }


  /**
   * Unlock user account
   *
   * @param token
   */
  userUnlock(token: string): Observable<any> {
    return this._api.usersUnlock({
      unlock_token: token
    });
  }
  /**
   * If user from VN or user acc is VN
   */
  public get isVN(): boolean {
    return this._env.env.country.short === 'vn' || (this._user.auth && this._user.info.country === 'VN');
  }

  /**
   * If user from CA or user acc is CA
   */
  public get isCA(): boolean {
    return this._env?.env?.country?.short === 'ca' || (this._user?.auth && this._user?.info?.country === 'CA');
  }

  /**
   * If user from CA or user acc is CA
   */
  public get isAU(): boolean {
    return this._env.env.country.short === 'au' || (this._user.auth && this._user.info.country === 'AU');
  }
}
