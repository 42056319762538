<div class="form-control" ClickOutside (clickOutside)="clickedOutside()"
  [ngClass]="{'touched-or-focused': control?.value || isFocused || isPlaceholderOrValuePresent}"
  >
  @if (label) {
    <span class="form-control__label" (click)="onLabelClick()">{{ label }}</span>
  }
  <div #input class="form-control__input" (click)="fixCaretPosition()">
    <ng-content ></ng-content>
  </div>
  @if (!control || (control && control.touched)) {
    @if (errorType === 'tooltipError') {
      @for (error of errors; track error) {
        <div
          class="form-control__error tooltip"
          >
          {{ error | translate }}
        </div>
      }
    }
  }
</div>
