import { xmasStore } from './xmas.store';
import { valentineStore } from './valentine.store';

export const GlobalStore = {
  XMAS_STORE: xmasStore,
  VALENTINE_STORE: valentineStore,
};

export enum GlobalStoreName {
  XMAS_STORE = 'XMAS_STORE',
  VALENTINE_STORE = 'VALENTINE_STORE'
}
