import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SleighDataItem, Xmas, XmasPage, XmasStoreEntity } from '../../../core/store/xmas.store';
import { BonusStage } from '../../../core/services/user/data/user-bonuses.data';
import { useStore } from 'ngx-unificator/store';
import { GlobalStoreName } from '../../../core/store';
import { GameCategory } from '../../../core/services/games/game-category';
import { ITournament } from '../../tournaments/tournaments.interface';
import { BasePromo } from '../base-promo';
import { EventType, WelcomePromoModal } from '../base-promo.interface';
import { BonusesStatuses } from '../../../core/services/groups.service';

export enum XmasAction {
  GO_TO_DEPOSIT = 'deposit',
  TOURNAMENTS = 'tournaments',
  PROMO_AUTH = 'promo-auth',
  GET_BONUS = 'get-bonus',
  GO_TO_BONUSES = 'bonuses',
  GAMES = 'games',
}

export const ALREADY_SPIN_PROMO_STR = 'alreadySpinPromo';

@Injectable({
  providedIn: 'root',
})
export class XmasService extends BasePromo {

  public xmasStore = useStore<XmasStoreEntity>(GlobalStoreName.XMAS_STORE);

  public eventName = 'xmas';

  private _actionRoutesConfig = {
    [XmasAction.GET_BONUS]: {
      key: null,
      url: '/profile/account',
      authUrl: ['profile', 'account'],
    },
    [XmasAction.GO_TO_BONUSES]: {
      key: null,
      url: '/bonuses',
      authUrl: ['bonuses'],
    },
    [XmasAction.TOURNAMENTS]: {
      key: 'promo-tournaments',
      url: '/tournaments',
      authUrl: ['tournaments'],
    },
    [XmasAction.GO_TO_DEPOSIT]: {
      key: 'promo-deposit',
      url: '/account/deposit',
      authUrl: ['account', 'deposit'],
    },
    [XmasAction.PROMO_AUTH]: {
      key: 'promo-auth',
      url: '/promo/xmas',
      authUrl: null,
    },
    [XmasAction.GAMES]: {
      key: null,
      url: '/games/winter-favorites',
      authUrl: ['games', 'winter-favorites'],
    },
  };

  get store(): Xmas {
    return this.xmasStore.storeSignal();
  }

  public getPromo$(): Observable<XmasPage> {
    return this._fetchPromoData$().pipe(
      filter(data => !!data[0]),
      map(([promo, lootbox, fs, bonuses]) =>
        this._processPromoData$(promo, lootbox, fs, bonuses)),
      tap(([promo, lootbox, fs, bonuses]) =>
        this._updateXmasStore(lootbox, fs, bonuses, promo)),
      map(([promo, lootbox, fs, bonuses]) =>
        this._processAdditionalPromoData$(promo, lootbox, fs, bonuses),
      ),
      map(list => list[0]),
      tap((list) => {
        this._updateSleighStore(list?.SleighData);
        console.log(this.xmasStore.storeSignal());
        if (this.platform.isBrowser) {
          setTimeout(() => {
            this.xmasStore.storeSignal.update(store => {
              return {
                ...store,
                isLoadedPromo: true,
              };
            });
          }, 300);
        }
      }),
    );
  };

  public onSpinBonus() {
    this.xmasStore.storeSignal.update(store => {
      return {
        ...store,
        isSpinBonus: true,
      };
    });
  }

  public tournaments$: Observable<ITournament[]> = this._getTournaments$();

  public readQueryParamsDepositSuccess() {
    this.route.queryParams.pipe(
      filter(({ deposit_success }) => !!deposit_success),
      tap(() => this.cookie.delete(ALREADY_SPIN_PROMO_STR)),
    ).subscribe();
  }

  private _getTournaments$(): Observable<any> {
    return this.tournaments.list({ with_game_list: 1 }).pipe(
      filter((tournaments) => !!tournaments),
      map(tournaments => tournaments.now.filter(tournament => {
        return tournament.slug.includes('xmas');
      })),
    );
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory$(): Observable<any> {
    return this.cmsApi.gameList({ category: GameCategory.WINTER_FAVORITES})
      .pipe(
        map(list => this.array.toChunks(
          this.games.gameListMapper(
            this.array.checkAndCloneArray(list.data.gameList, this.breakpoint.belowSmallDesktop ? 4 : 5)),
          this.breakpoint.belowSmallDesktop ? 4 : 5)),
      );
  }

  public async showBonusDescModal(page: XmasPage) {
    const component = await import('../../../core/modal-v2/components/lazy/xmas-description-bonus-modal/xmas-description-bonus-modal.component');
    await this.modals.openLazy(
      component?.XmasDescriptionBonusModalComponent,
      {
        template: 'CLEAR',
        data: {
          firstTitle: page?.SecretFuelModalFirstTitle,
          secondTitle: page?.SecretFuelModalSecondTitle,
        },
      });
  }

  public async onAction(
    action: XmasAction,
    bonusCode: string = '',
    successUrl: string = this.window.nativeWindow.location.href + '?deposit_success=true',
  ) {
    const routeConfig = this._actionRoutesConfig[action];
    const { key, url, authUrl } = routeConfig;

    switch (action) {
      case XmasAction.PROMO_AUTH:
      case XmasAction.TOURNAMENTS:
      case XmasAction.GO_TO_BONUSES:
      case XmasAction.GET_BONUS:
      case XmasAction.GAMES:
        await this._handleActionRoute(key, url, authUrl);
        break;
      case XmasAction.GO_TO_DEPOSIT:
        await this._handleActionRoute(
          key,
          url,
          authUrl,
          { successUrl, bonusCode },
          { queryParams: { successUrl, bonusCode } },
        );
        break;
    }
  }

  public onCompleteSpin() {

    const {
      promoLootbox,
      welcomeLootbox,
      fs,
      cash,
    } = this.store.currentBonus || {};

    const cashPrize = cash ? `${cash.amount} ${cash.currency}` : undefined;

    if (this.store.isUserCanPartiallyParticipateInPromo) {
      if (welcomeLootbox) {
        this._activateLootbox$(welcomeLootbox.id).pipe(
          switchMap((response) => {
            const fs_count = this.bonuses.resolveBonusAttributes(
              response?.bonuses[0].attributes)?.freespins_count;
            this._updateCurrentPrize(fs_count, cashPrize ?? undefined);
            return this.bonuses.activateFreeSpins(response?.id);
          }),
        ).subscribe();
      } else if (fs) {
        this.bonuses.activateFreeSpins(fs?.id).subscribe(() => {
          this._updateCurrentPrize(fs?.freespins_total, cashPrize ?? undefined);
        });
      }
    }

    if (this.store.isUserCanParticipateInPromo && promoLootbox) {
      this._activateLootbox$(promoLootbox?.id).pipe(
        switchMap((response) => {
          const fs_count = this.bonuses.resolveBonusAttributes(
            response?.bonuses[0].attributes)?.freespins_count;
          this._updateCurrentPrize(fs_count);
          return this.bonuses.activateFreeSpins(response?.id);
        }),
      ).subscribe();
    }
  }



  private _updateCurrentPrize(fs_count: number, cash?: string) {
    this.xmasStore.storeSignal.update(store => ({
      ...store,
      currentPrize: {
        cash,
        fs_count,
      },
    }));
    this.cookie.set(ALREADY_SPIN_PROMO_STR, '1');
  }

  private _activateLootbox$(id: number): Observable<any> {
    return this.lootboxService.activateLootBox(id);
  }


  private async _handleActionRoute(key: string, url: string, authUrl: string[], urlData?: any, extras?: any) {
    const urlParams = { key, url, authUrl, urlData, extras };
    if (!key) {
      await this.router.navigateByUrl(url);
      return;
    }
    if (!this.user.auth) {
      this.local.addUrl(urlParams.key, urlParams.url, urlParams.urlData);
      await this.user.authUser();
    } else {
      await this.router.navigate(urlParams.authUrl, urlParams.extras);
    }
  }

  /**
   * Function to fetch Halloween promotion data from API
   */
  private _fetchPromoData$(): Observable<[
    XmasPage[], any[], any[], any[]]> {
    return combineLatest([
      this.page.item({
        slug: this.eventName
      }),
      this.user.auth ? this.lootboxService.loadUserLootBox() : of([]),
      this.user.auth ? this.bonuses.freeSpinsList() : of([]),
      this.user.auth ? this.bonuses.bonusList() : of([]),
    ]);
  };

  private _processPromoData$(
    promo: XmasPage[],
    lootbox: any[],
    fs: any[],
    bonuses: any[]): any {
    return [
      this._resolveByRangeWeekDate(promo),
      lootbox,
      fs,
      bonuses,
    ];
  };

  private _processAdditionalPromoData$(
    promo: XmasPage[],
    lootbox: any[],
    fs: any[],
    bonuses: any[]): any {
    return promo?.map(promoItem => ({
      ...promoItem,
      fs,
      lootbox,
      bonuses,
      SleighData: this._resolveSleigh(promoItem?.SleighData, fs, lootbox),
      HowWorksSteps: [...Object.values(promoItem?.HowWorksSteps)],
      unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
      publishAt: this.time.toLocalDate(promoItem?.publishAt),
    }));
  };

  private _updateXmasStore(lootbox: any, fsBonuses: any, bonuses: any, promo: XmasPage): void {

    const cash = this._getFilteredCashWelcomeBonuses(bonuses);
    const fs = this._getFilteredFreespinsWelcomeBonuses(fsBonuses);
    const welcomeLootbox = this._getFilteredWelcomeLootbox(lootbox);
    const promoLootbox = this._getFilteredPromoLootbox(lootbox);

    const allWelcomePackClaimed = this.group.isExistGroup(
      BonusesStatuses.ALL_WELCOME_OFFERS_USED);

    const isUserAlreadyActivatedPromoLootbox =
      this._checkActivatedTodayLootbox(lootbox);

    const isUserCanParticipateInPromo = Boolean(allWelcomePackClaimed && promoLootbox);

    const isUserCanPartiallyParticipateInPromo =
      Boolean((cash || fs || welcomeLootbox) && !promoLootbox);

    const weeklyCompletedBonusesCount = allWelcomePackClaimed ?
      this._countWeeklyBonus() : 0;

    const isWeeklyMissionsCompleted =
      weeklyCompletedBonusesCount === this.store.weeklyCompletedBonusesLimit;

    this.xmasStore.storeSignal.update(store => {
      return {
        ...store,
        currentBonus:
          fs && cash || welcomeLootbox && cash || fs || promoLootbox ? {
            cash,
            fs,
            welcomeLootbox,
            promoLootbox,
          } : null,
        isUserCanParticipateInPromo,
        isUserCanPartiallyParticipateInPromo,
        isUserAlreadyActivatedPromoLootbox,
        weeklyCompletedBonusesCount,
        isWeeklyMissionsCompleted,
      };
    });
  }

  private _updateSleighStore(list: SleighDataItem[]) {
    this.xmasStore.storeSignal.update(store => {
      return {
        ...store,
        sleighData: list,
        currentSleighItem: list.find(item => item.currentDayIndex),
        currentSleighItemIndex: list.findIndex(item => item.currentDayIndex),
      };
    });
  }

  private _getFilteredPromoLootbox(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(lootbox =>
      lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredWelcomeLootbox(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(lootbox => lootbox.isWelcome &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredFreespinsWelcomeBonuses(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.ISSUED &&
      bonus.isWelcome && !this.cookie.check(ALREADY_SPIN_PROMO_STR))
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _getFilteredCashWelcomeBonuses(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(bonus =>
      bonus.stage === BonusStage.HANDLE_BETS &&
      bonus.isWelcome && !this.cookie.check(ALREADY_SPIN_PROMO_STR))
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _checkActivatedTodayLootbox(arrayTcCheck: any[]): boolean {
    return arrayTcCheck?.some(lootbox =>
      lootbox.stage === BonusStage.ACTIVATED &&
      lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      this.time.isTodayDate(lootbox.created_at));
  }

  private _formatShortDate(inputDate: any) {
    const date = new Date(inputDate);
    const monthNames = [
      't.jan', 't.feb', 't.mar', 't.apr', 't.may', 't.jun',
      't.jul', 't.aug', 't.sep', 't.oct', 't.nov', 't.dec'];

    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    return `${day} ${this.translate.translate(month)}`;
  }

  private _getRangeOfWeekDates(diapasonRange: number = 7, startDate?: Date) {
    const baseDate = startDate ? new Date(startDate) : new Date();
    const dates = [];
    const currentDay =
      baseDate.toLocaleString('en-us', { weekday: 'long' }).toLowerCase();
    const startWeekIndex = currentDay === 'sunday' ? -6 : 0;

    for (let i = 0; i < diapasonRange; i++) {
      const date = new Date(
        Date.UTC(
          baseDate.getUTCFullYear(),
          baseDate.getUTCMonth(),
          baseDate.getUTCDate() - baseDate.getUTCDay() + i +
          (baseDate.getUTCDay() === 0 ? startWeekIndex : 1),
          0, 0, 0,
        ),
      );
      const formattedDate =
        date.toISOString().split('T')[0] + ' 00:00:00.000000';
      dates.push(formattedDate);
    }

    return dates;
  }

  private _countWeeklyBonus(): number {
    switch (true) {
      case this.group.isExistGroup('ID958'):
        return 1;
      case this.group.isExistGroup('ID959'):
        return 2;
      case this.group.isExistGroup('ID960'):
        return 3;
      case this.group.isExistGroup('ID961'):
        return 4;
      default:
        return 0;
    }
  }

  private _currentIndexByCurrentDay(list: any[]): number {
    if (list) {
      const todayUTC = new Date().toISOString().split('T')[0];
      return list.findIndex(item => item.date.startsWith(todayUTC));
    }
  }

  private _resolveByRangeWeekDate(promo: XmasPage[]): XmasPage[] {
    return [
      {
        ...promo?.[0],
        SleighData: this.xmasStore.storeSignal().defaultSleighArray
          .map((item: number, i: number) => {
            return {
              date: this._getRangeOfWeekDates(
                this.xmasStore.storeSignal().defaultSleighArray?.length,
                new Date(promo?.[0]?.StartPromoDate))[i],
              short_date: this._formatShortDate(
                this._getRangeOfWeekDates(
                  this.xmasStore.storeSignal().defaultSleighArray?.length,
                  new Date(promo?.[0]?.StartPromoDate))[i],
              ),
            };
          }),
      },
    ];
  };

  private _resolveSleigh(
    sleigh: SleighDataItem[],
    fs: any[],
    lootbox: any[]): any[] {

    return sleigh.map((sleighItem: SleighDataItem, i: number) => {


      const date = new Date(sleighItem?.date);
      const formattedDate = date?.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
      }).replace(/\//g, '.');

      return {
        ...sleighItem,
        locked: this._resolveLockedState(sleighItem?.date),
        taken: this._resolveCompletedState(sleighItem?.date, fs, lootbox),
        currentDayIndex: this._currentIndexByCurrentDay(sleigh) === i,
        date: formattedDate,
        prizeTitle: `t.prize-title-${i + 1}`,
        isCelebratedDay: date.getDay() === 0 || i === 29,
      };
    });
  }

  private _resolveCompletedState(
    date: string,
    fs: any[],
    lootbox: any[]): boolean {

    const isTakenStages = [
      BonusStage.ACTIVATED,
      BonusStage.FINISHED,
      BonusStage.CANCELED,
      BonusStage.PLAYED,
      BonusStage.EXPIRED,
    ];

    const isFsActivated = fs
      .filter(f => f.title.trim() === 'xmas')
      .some(fsBonus => isTakenStages.includes(fsBonus.stage) &&
        this.time.areSameUTCDates(fsBonus.created_at));

    const isLootboxActivated = lootbox
      .filter(l => l?.e?.title?.trim() === 'landing')
      .some(lootboxBonus => isTakenStages.includes(lootboxBonus.stage) &&
        this.time.areSameUTCDates(lootboxBonus.created_at));

    return (isFsActivated || isLootboxActivated) ||
      this._resolveTakenState(date);
  }

  private _resolveLockedState(date: string): boolean {
    const currentDate = new Date();
    const currentDateUTC = currentDate.toISOString();
    const originalDate = new Date(currentDateUTC);
    const convertedDateStr =
      originalDate.toISOString().split('T')[0] + ' 00:00:00.000000';
    return date > convertedDateStr || date < convertedDateStr;
  }

  private _resolveTakenState(date: string): boolean {
    const currentDate = new Date();
    const currentDateUTC = currentDate.toISOString();
    const originalDate = new Date(currentDateUTC);
    const convertedDateStr =
      originalDate.toISOString().split('T')[0] + ' 00:00:00.000000';
    return date < convertedDateStr;
  }

  checkActivatedBonuses(data: WelcomePromoModal): Observable<any> {
    return of();
  }

  openHuntModal(type: EventType): void {
  }
}
