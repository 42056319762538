export const PresetStateValues = {
  quickSumCurrencyDeposit: {
    default: {
      default: [50, 100, 200],
    },
    AUD: {
      default: [30, 50, 100, 200],
    },
    USD: {
      default: [30, 50, 100, 200],
    },
    EUR: {
      default: [10, 50, 100, 200],
    },
    NZD: {
      default: [17, 50, 100, 200],
    },
    NOK: {
      default: [200, 500, 1000, 2000],
    },
    MYR: {
      default: [200, 500, 1000, 2000],
    },
    CAD: {
      default: [30, 50, 150, 300],
    },
    ZAR: {
      default: [400, 1000, 2000, 4000],
    },
    JPY: {
      default: [2500, 6000, 12000, 24000],
    },
    HUF: {
      default: [10000, 15000, 30000, 150000],
    },
    PLN: {
      default: [90, 200, 500, 900],
    },
    BRL: {
      default: [30, 50, 100, 200],
    },
    VND: {
      default: [100000, 200000, 500000, 1000000],
    },
    IDR: {
      default: [100000, 200000, 500000, 1000000],
    },
    THB: {
      default: [400, 500, 1000, 5000],
    },
    PHP: {
      default: [500, 1000, 2000, 4000],
    },
    KRW: {
      default: [10000, 50000, 100000, 500000],
    },
  },
  quickSumCurrencyWithdrawal: {
    default: {
      default: [100, 300, 500],
    },
    AUD: {
      default: [100, 320, 600, 1000],
    },
    EUR: {
      default: [20, 50, 100, 200],
      fixipay: [100, 200, 400, 800]
    },
    NZD: {
      default: [30, 80, 150, 300],
    },
    CAD: {
      default: [30, 80, 150, 300],
    },
  },
  standardSumCurrencyDeposit: {
    AUD: 50,
    EUR: 50,
    USD: 50,
    NZD: 50,
    NOK: 1000,
    CAD: 150,
    ZAR: 2000,
    JPY: 12000,
    HUF: 30000,
    PLN: 500,
  },
  stepAmountByPreset: {
    AUD: 50,
    USD: 50,
    EUR: 50,
    NOK: 500,
    MYR: 600,
    CAD: 100,
    ZAR: 1200,
    JPY: 7170,
    HUF: 46670,
    PLN: 270,
    BRL: 100,
    VND: 300000,
    IDR: 300000,
    THB: 1530,
    PHP: 1170,
    KRW: 163330,
  },
}
