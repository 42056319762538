import {AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import { CreditCardTypeCardBrandName } from '../services/credit-card-type.service';
import {ValidationPatterns} from './validation-patterns';

export class CustomValidators {

  /**
   * Uses when two inputs in form group should have identical values (password confirmation for example)
   *
   * @param controlName
   * @param matchingControlName
   * @constructor
   */
  public static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ must_match: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * Uses for override error key that uses native validators
   *
   * Example changeKey('email_pattern', Validators.pattern(<email validation regexp>))
   *  result: {email_pattern: true}
   *
   * If you will just use Validators.pattern(<email validation regexp>)
   *  result should be: {pattern: true}
   *
   * @param newKey
   * @param validator
   */
  public static changeKey(newKey: string, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const invalid = validator(control);

      if (invalid) {
        const validationResult = invalid[Object.keys(invalid)[0]];

        return {
          [newKey]: validationResult
        };
      } else {
        return null;
      }
    };
  }

  /**
   * Is user eighteen or more years old
   *
   * @param control
   */
  public static eighteenYearsOld(control: AbstractControl): {[key: string]: any} | null {
    if (control.value) {
      let [dd, mm, yy] = control.value.split('/');

      dd = Number(dd || 0);
      mm = Number(mm || 0);
      yy = Number(yy || 0);

      const invalid = new Date(yy + 18, mm - 1, dd) > new Date();

      if (invalid) {
        return {
          eighteen_years: invalid
        };
      } else {
        return null;
      }
    }
  }

  /**
   * Is user input spaces
   * @param control
   */
  public static checkSpaces(control: AbstractControl) {
    if (/\s/.test(control.value)) {
      return {contain_spaces: true};
    }
  }

  /**
   * Uses for payment methods VISA or MASTERCARD
   *
   * Example validateVisaOrMastercard('VISA', RegExp for Payment brand)
   *
   * If the entered card number does not pass through the regular expression
   * for VISA result should be: {use_mastercard: true
   * for MASTERCARD result should be: {use_visa: true}
   *
   * @param method
   * @param regExp
   */
  public static validateVisaOrMastercard(method: CreditCardTypeCardBrandName, regExp: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (method !== 'VISA' && method !== 'MASTERCARD' || !regExp) {
        return null;
      } else {
        if (ValidationPatterns.creditCard.test(control.value)) {
          if (method === 'VISA') {
            if (!regExp.test(control.value)) {
              return  { use_mastercard: true };
            }
          } else {
            if (!regExp.test(control.value)) {
              return  { use_visa: true };
            }
          }
        } else {
         return { pattern: true };
        }
      }
    };
  }

  /**
   * Checkbox Validation
   * @param {number} min
   * @param {number} name
   * @returns {ValidatorFn}
   */
  public  static minSelectedCheckboxes(min: number = 1, name: string) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value[name])
        .reduce((prev, next) => next ? prev + next : prev, 0);
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  public static validateYear(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {

      const yearValue = parseInt(control.value, 10);
      const currentYear = new Date().getFullYear() % 100;

      if (yearValue < currentYear) {
        return { yearInPast: true };
      }

    }
  }

}
