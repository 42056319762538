/**
 * Available game attributes
 */
export enum GameAttribute {
  NEW = 32,
  POPULAR = 33,
  PRE_RELEASE = 147,
  BEST = 33,
  TOURNAMENT = 35,
  JACKPOT = 102,
  FS = 37,
  FLASH = 31,
  ADAPTIVE_FLASH = 24,
  LIVE = 27,
  NODEMO = 26,
  DAILY_DROPS = 68,
  BITCOIN = 70,
  FREE = 74,
  MEGAWAYS = 75,
  JACKPOT_SLUG = 'jackpot-matsuri',
  JACKPOT_GAMES = 'jackpot',
  GROUP_TOURNAMENT = 'group-tournament',
  MYSTERY_DROP = 155,
  BGAMING_DROPS = 158,
  CASHOUT_CANCELED = 171,
}
