import { computed, inject, Injectable, signal } from '@angular/core';
import { Payment } from 'payments-lib-types';
import { UserService } from './user/user.service';
import { TransactionStatus, UserTransactionsService } from './user/user-transactions.service';
import { LocalstorageService } from './localstorage.service';
import { PaymentsMethod } from '../vendor/ss-payments-v2/ss-payment-types';
import { PresetStateValues } from './user/data/preset-state-values';

export interface PresetState {
  quickSumPresets: number[];
  increasedAmount: number | undefined,
  quickSumCurrencyDeposit: {
    [key: string]: {
      default: number[]
    },
  },
  quickSumCurrencyWithdrawal: {
    [key: string]: {
      [key: string]: number[]
    },
  },
  standardSumCurrencyDeposit: {
    [key: string]: number
  },
  stepAmountByPreset: {
    [key: string]: number
  }
}

@Injectable({
  providedIn: 'root',
})
export class PresetService {

  public state = computed(() => this._state());

  private _user: UserService =
    inject(UserService);

  private _transactions: UserTransactionsService =
    inject(UserTransactionsService);

  private _storage: LocalstorageService =
    inject(LocalstorageService);

  private _state = signal<PresetState>({
    quickSumPresets: [],
    increasedAmount: undefined,
    ...PresetStateValues
  });


  /**
   * Apply default presets
   * @param action
   * @param method
   * @param minAmount
   */
  public defaultPresets(action: Payment.Action, method: PaymentsMethod, minAmount: number) {
    const selectQuickSumByCurrency =
      action === Payment.Action.Deposit
        ? this.selectQuickSumByCurrencyDeposit
        : this.selectQuickSumByCurrencyWithdrawal;

    const defaultPresets = selectQuickSumByCurrency(
        method?.brand,
        this._user.currentCurrency.currency,
      ) ||
      (action === Payment.Action.Deposit ?
        this.state().quickSumCurrencyDeposit?.default?.default :
        this.state().quickSumCurrencyWithdrawal?.default?.default);

    const isCustomPresetAvailable = selectQuickSumByCurrency(
      method?.brand,
      this._user.currentCurrency.currency,
    );

    if (!isCustomPresetAvailable) {
      this._updateDefaultQuickSumStore(
        [
          method?.termsOfService
            .restrictions
            .minAmountValue,
          ...defaultPresets,
        ],
      );
      return;
    }

    this._updateDefaultQuickSumStore([...defaultPresets]);

    if (defaultPresets[0] < minAmount) {
      let minPresets = [...defaultPresets];
      minPresets[0] = minAmount;
      if (minPresets[1] < minPresets[0]) {
        minPresets = minPresets.map((e, i) => i === 0 ? minAmount : minAmount + e)
      }
      this._updateDefaultQuickSumStore([...minPresets]);
    }
  }

  /**
   * Apply increased default amount
   * @param defaultSum
   */
  public increasedDefaultAmount(defaultSum: number) {
    const currentCurrency = this._user.currentCurrency.currency;

    const transactionsList = this._transactions.transactionList$
      .getValue()
      .sort((a, b) => b.id - a.id);

    const lastTransaction = transactionsList?.find(
      (transaction) =>
        transaction.currency === currentCurrency &&
        transaction.status === TransactionStatus.ACCEPTED,
    );

    const storedIncreasedAmountData =
      JSON.parse(this._storage.get('upsale') || '[]');

    const storedTransaction = storedIncreasedAmountData?.find(
      (item) => item?.currency === currentCurrency,
    );

    let increasedAmount = defaultSum;

    if (storedTransaction) {
      increasedAmount = storedTransaction.amount;
    }

    if (lastTransaction) {
      const baseAmount = lastTransaction.amount;

      if (!storedTransaction && baseAmount < defaultSum) {
        increasedAmount = defaultSum;
      } else if (storedTransaction && storedTransaction.amount > baseAmount) {
        increasedAmount = storedTransaction.amount;
      } else if (baseAmount > defaultSum) {
        increasedAmount = parseFloat((baseAmount * 1.1).toFixed(2));

        const existingIndex = storedIncreasedAmountData.findIndex(
          (item) => item.currency === currentCurrency,
        );

        if (existingIndex !== -1) {
          storedIncreasedAmountData[existingIndex].amount = increasedAmount;
        } else {
          storedIncreasedAmountData.push({
            amount: increasedAmount,
            currency: currentCurrency,
          });
        }

        this._storage.set('upsale', JSON.stringify(storedIncreasedAmountData));
      }
    }

    this._updateIncreasedAmount(increasedAmount);
  }

  /**
   * Apply increased amount presets
   * @param method
   * @param defaultAmount
   */
  public increasedAmountPresets(method: PaymentsMethod, defaultAmount: number) {
    const defaultPresets = this.selectQuickSumByCurrencyDeposit
      (method.brand,
        this._user.currentCurrency.currency) ||
      this.state().quickSumCurrencyDeposit?.default?.default;

    if (this._state().increasedAmount > defaultAmount) {
      this._updateDefaultQuickSumStore(
        [...this._updateIncreasedAmountPresets(
          defaultPresets,
          this.state().increasedAmount,
          this.state().stepAmountByPreset[this._user.currentCurrency.currency]),
        ],
      );
    } else {
      this._updateDefaultQuickSumStore([...defaultPresets]);
    }
  }

  /**
   * Select presets by currency or brand for deposit
   * @param brand
   * @param currency
   */
  public selectQuickSumByCurrencyDeposit = (brand, currency) =>
    (this.state().quickSumCurrencyDeposit[currency] || null) &&
    (this.state().quickSumCurrencyDeposit[currency][brand] ||
      this.state().quickSumCurrencyDeposit[currency].default);

  /**
   * Select presets by currency or brand for withdrawal
   * @param brand
   * @param currency
   */
  public selectQuickSumByCurrencyWithdrawal = (brand, currency) =>
    (this.state().quickSumCurrencyWithdrawal[currency] || null) &&
    (this.state().quickSumCurrencyWithdrawal[currency][brand] ||
      this.state().quickSumCurrencyWithdrawal[currency].default);

  /**
   * Select default amount by currency or apply min amount
   * @param currency
   * @param minAmount
   */
  public selectStandardSumDeposit = (currency, minAmount) =>
    this.state().standardSumCurrencyDeposit[currency] || minAmount;

  /**
   * Update default presets in store
   * @param quickSumPresets
   * @private
   */
  private _updateDefaultQuickSumStore(quickSumPresets: number[]) {
    this._state.update(store => {
      return {
        ...store,
        quickSumPresets,
      };
    });
  }

  /**
   * Update increased amount in store
   * @param increasedAmount
   * @private
   */
  private _updateIncreasedAmount(increasedAmount: number) {
    this._state.update(store => {
      return {
        ...store,
        increasedAmount,
      };
    });
  }

  /**
   * Offset default preset and apply increased amount presets
   * @param array
   * @param value
   * @param step
   * @private
   */
  private _updateIncreasedAmountPresets(
    array: number[], value: number, step: number,
  ): number[] {
    const filteredArray = array.filter(item => item > value);
    const start = filteredArray[0] || 0;
    const resultArray = [];

    for (let i = start; resultArray.length < 4; i += step) {
      resultArray.push(i);
    }

    return resultArray;
  }

}
