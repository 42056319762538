export class ValidationPatterns {

  /**
   * Pattern for email validation
   */
  public static readonly email = /^(([^<>()\[\]\\.,'`"|!%=+?&*^$#/;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([一-龠ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{2,}))$/;

  /**
   * Pattern for password validation
   */
  public static readonly password = /.{8,}/;

  /**
   * Pattern for phone number validation
   */
  public  static readonly phoneNumber = /(^[+]?[0-9]+)$/;

  /**
   * Pattern for cvv validation
   */
  public static readonly cvv = /^[0-9]{3,4}$/;

  /**
   * Pattern only numbers
   */
  public static readonly numbers = '^[0-9]*$';

  /**
   * Pattern only letters
   */
  public static readonly letters = /^[\u0600-\u06FF ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'-.\-]+$/;

  /**
   * Pattern for creditCard validation
   */
  public static readonly creditCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

  /**
   * Pattern for date of birth validation
   */
  public static readonly dateOfBirth = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/;

  /**
   * Pattern for validate spaces at the start and end
   */
  public static readonly noSpaceAtStart = /^[^\s]+(\s+[^\s]+)*$/;

  /**
   * Pattern for all languages letters and numbers without spaces
   */
  public static readonly lettersAndNumbers = /^[\u0600-\u06FF ぁ-ゔァ-ヴーA-Za-zwàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9]+$/;

  /**
   * Pattern for all languages letters with spaces with hyphen
   */
  public static readonly onlyLettersAndSpacesAndHyphenBracesPoint = /^[\u0600-\u06FF ぁ-ゔァ-ヴーA-Za-zwàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð(.)\s-]+$/;

  /**
   * Pattern for all languages letters and numbers with spaces and some symbols
   */
  public static readonly lettersAndNumbersWithSpacesAndSomeSymbols = /^[\u0600-\u06FF ぁ-ゔァ-ヴーA-Za-zwàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s-.,`']+$/;

  /**
   * Pattern for all specific symbols
   */
  public static readonly specialSymbols = /[$&+,`~{}\]\[_€£:;=?@#/\/| '\\<>.^*()%!-]/g;

  /**
   * Pattern with no spaces and symbols
   */
  public static readonly noSpacesAndSymbols = /^[^\W_]+$/;

  /**
   * Pattern for country code, which consists of 2 letters
   */
  public static readonly twoLetters = /^[a-z]{2}$/i;

  /**
   * Pattern for canadian postal code
   */
  public static readonly canadianPostalCode = /[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/;

  /**
   * Pattern letters and special symbols
   */
  public static readonly lettersAndSpecialSymbols = /^[$&+,`~{}\]\[_€£:;=?@#/\/| '\\<>.^*()%![\u0600-\u06FF ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'-.\-]+$/;

}
