import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { ModalContent, ModalRef } from '../../../modal-ref';
import { UpdateProfileFormController } from '../../../../helpers/user/update-profile-form-controller';
import { UserFieldType } from '../../../../helpers/user-fields.data';
import { GoogleTagManagerService } from '../../../../services/google-tag-manager.service';
import { EnvironmentService } from '../../../../services/environment.service';
import { UserService } from '../../../../services/user/user.service';
import { UserInfoService } from '../../../../services/user/user-info.service';
import { ArabicService } from '../../../../services/arabic.service';
import { GeoApifyService } from 'src/app/core/services/geoapify.service';
import { ReversePipe } from '../../../../shared/pipes/reverse.pipe';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { SelectOptionComponent } from '../../../../modules/select/select-option/select-option.component';
import { SelectDropdownComponent } from '../../../../modules/select/select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from '../../../../modules/select/select-header/select-header.component';
import { SelectComponent } from '../../../../modules/select/select.component';
import { DobMaskDirective } from '../../../../shared/directives/dob-mask.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { InputLettersSymbolsDirective } from '../../../../shared/directives/input-letters-symbols.directive';
import { FormControlsModule } from '../../../../modules/form-controls/form-controls.module';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { UserPaymentSsV2Service } from '../../../../services/user/user-payment-ss-v2.service';
import { PhoneFormComponent } from '../../../../shared/components/phone-form/phone-form.component';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-auth-fields-missed-update',
    templateUrl: './auth-fields-missed-update.component.html',
    styleUrls: ['./auth-fields-missed-update.component.scss'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormControlsModule, NgTemplateOutlet, InputLettersSymbolsDirective, NgxMaskDirective, NgClass, DobMaskDirective, SelectComponent, SelectHeaderComponent, SelectDropdownComponent, SelectOptionComponent, PreloaderComponent, AsyncPipe, TranslatePipe, ReversePipe, PhoneFormComponent],
})
export class AuthFieldsMissedUpdateComponent implements OnInit, AfterViewInit, OnDestroy, ModalContent {

  /**
   * Edit profile form controller
   */
  public controller: UpdateProfileFormController;
  /**
   * Link to modal window
   */
  public modal: ModalRef;

  public readonly FieldType = UserFieldType;

  /**
   * Suggestion lists
   */
  public citiesSuggestion: Array<any> = [];
  public addressesSuggestion: Array<any> = [];
  public zipCodeSuggestion: Array<any> = [];

  public fields$ = new Observable();

  constructor(
    private _router: Router,
    private _gtm: GoogleTagManagerService,
    private _payment: UserPaymentSsV2Service,
    public env: EnvironmentService,
    private _geoApify: GeoApifyService,
    private _user: UserService,
    public userInfo: UserInfoService,
    public rtl: ArabicService,
  ) {
  }

  ngOnInit() {
    this.controller = new UpdateProfileFormController(this.modal.options.data?.context || 'deposit');
    this.controller.modal = this.modal;

    this.fields$ = this.controller.fields.pipe(
      finalize(() => this._initAutocomplete())
    )

    /**
     * Stop missing fields process
     */
    this._handleEvents();
  }

  submitForm(form: UntypedFormGroup) {
    this.controller.submit(form);
  }

  ngAfterViewInit() {
    //this._initAutocomplete();
  }

  private _handleEvents() {
    /**
     * Form result handler
     */
    this.controller.updated$.pipe(
      untilDestroyed(this),
      filter(updated => !!updated),
      tap(() => {
        if (this.modal.options.data && this.modal.options.data.url) {
          this._router.navigateByUrl(this.modal.options.data.url).then();
        }

        setTimeout(() => {
          this._router.navigateByUrl(this._router.url);
          this.modal.close(true);
        }, 100);
      })
    ).subscribe();
  }

  /**
   * Send gtm data on field focus
   */
  onFocus(e, customName: string = null) {
    customName ? this._gtm.registerFormInputFocus(customName) : this._gtm.registerFormInputFocus(e.target.name);
  }

  /**
   * Init handle input autocomplete
   *
   * @private
   */
  private _initAutocomplete() {
    const cityControl = this.controller.formForModal?.get('city');
    if (cityControl) {
      cityControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this),
        filter(value => value && value.length >= 3),
        switchMap((value: string) =>
          this._geoApify.getPredictions({
            type: 'city',
            text: value,
            filter: `countrycode:${this._user?.info?.country?.toLowerCase() || this.controller.formForModal.get('country')?.value?.toLowerCase() || ''}`,
          }),
        ),
        map((suggestions: any) => this._geoApify.filterCities(suggestions)),
        tap(suggestions => {
          this.citiesSuggestion = suggestions;
        }),
      ).subscribe();
    }

    const addressControl = this.controller.formForModal?.get('address');
    if (addressControl) {
      addressControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this),
        filter(value => value && value.length >= 3),
        switchMap(value =>
          this._geoApify.getPredictions({
            type: 'street',
            text: value,
            filter: `countrycode:${this._user?.info?.country?.toLowerCase() || this.controller.formForModal.get('country')?.value?.toLowerCase() || ''}`,
          }),
        ),
        map(suggestions => this._geoApify.filterAddress(suggestions)),
        tap(suggestions => {
          this.addressesSuggestion = suggestions;
        }),
      ).subscribe();
    }

    const zipCodeControl = this.controller.formForModal?.get('postal_code');
    if (zipCodeControl) {
      zipCodeControl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value && value.length >= 1),
        switchMap(value =>
          this._geoApify.getPredictions({
            type: 'postcode',
            text: value,
            filter: `countrycode:${this._user?.info?.country?.toLowerCase() || this.controller.formForModal.get('country')?.value?.toLowerCase() || ''}`,
          }),
        ),
        map(suggestions => this._geoApify.filterAddress(suggestions)),
        tap(suggestions => {
          this.zipCodeSuggestion = suggestions;
        }),
      ).subscribe();
    }
  }

  /**
   * Select suggested value
   *
   * @param suggestion
   * @param input
   */
  selectSuggestion(suggestion, input: string) {
    switch (input) {
      case 'city':
        if (this.controller.formForModal.get('address').value) {
          this.controller.formForModal.get('address').setValue(null);
          this.controller.formForModal.get('address').markAsUntouched();
        }
        this.controller.formForModal.get('city').setValue(suggestion.city);
        this.controller.formForModal.get('postal_code').setValue(suggestion.postcode);
        break;
      case 'address':
        this.controller.formForModal.get('address').setValue(suggestion.street);
        this.controller.formForModal.get('postal_code').setValue(suggestion.postcode);
        break;
      case 'postal_code':
        this.controller.formForModal.get('postal_code').setValue(suggestion.postcode);
        break;
    }
  }

  ngOnDestroy() {
    this._payment.isSecondDepositStep = this._payment.hidePlayerFieldsInPaymentForm;
  }
}
