import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { BonusShopPage, GoodsItem } from '../../page/bonus-shop/bonus-shop.types';
import { filter, map, switchMap } from 'rxjs/operators';
import { StaticPageService } from '../../page/static-page/static-page.service';
import { SsApiService } from './api/ss-api.service';
import { ModalService } from '../modal-v2/modal.service';
import { FiltersService } from './filters.service';
import { UserService } from './user/user.service';
import { CommonDataService } from './common-data.service';

export enum BONUS_SHOP_TYPE {
  CASH = 'cash',
  COINS = 'coins',
}
@Injectable({
  providedIn: 'root',
})
export class BonusStoreService {

  public bonusStorePage$: Observable<BonusShopPage[]>;
  constructor(
    private _page: StaticPageService,
    private _modal: ModalService,
    private _filters: FiltersService,
    private _ssApi: SsApiService,
    private _user: UserService,
    private _common: CommonDataService,
  ) {
  }

  public getAndMappedPage$(bonusShopType: BONUS_SHOP_TYPE = BONUS_SHOP_TYPE.CASH): Observable<BonusShopPage> {
    return this._getStorePage$(bonusShopType).pipe(
      map(list => {
        this.matchLootboxesWithImages(list);
        return {
          ...list,
          Goods: list.Goods.map(item => {
            const prizeEntries = Object.entries(item).filter(([key]) => key.includes('Prize'));
            const prizeObject: { [key: string]: string } = {};
            prizeEntries.forEach(([key, value]) => {
              if (typeof value === 'string') {
                prizeObject[key] = value;
              }
            });
            return {
              ...item,
              PointsCost: item.PointsCost ? Number(item.PointsCost) : null,
              Prizes: this.transformPrizeData(prizeObject),
            };
          }),
        };
      }),
      map(list => {
        return {
          ...list,
          Goods: this._filters.sortByProp(list?.Goods, 'PointsCost'),
        };
      }),
    );
  }

  private _getStorePage$(bonusShopType: BONUS_SHOP_TYPE): Observable<BonusShopPage> {
    return this._user.auth$.pipe(
      switchMap(auth => {
        return combineLatest([
          this._page.item({ slug: `bonus-store-${bonusShopType}` }),
          bonusShopType === BONUS_SHOP_TYPE.CASH && auth ? this._ssApi.productStoreProductsGet() : of([]),
        ]).pipe(
          filter(list => !!list[0]),
          map((response: any[]) => {
            return response[0].map(item => {
              return {
                ...item,
                Goods: this._mapPricesToGoods(
                  [...Object.values(item?.Goods)],
                  response[1]?.products?.length ? response[1]?.products : null,
                ),
                HowEarnSteps: [...Object.values(item?.HowEarnSteps)],
              };
            });
          }),
          map(list => list[0]),
        );
      }),
    );
  }


  public transformPrizeData(data: any) {
    const result = [];
    for (const key in data) {
      const prize = data[key];
      const title = key.replace(/Prize$/, '');
      const isLegendary = title.includes('Legendary');
      const isRare = title.includes('Rare');
      const isMythical = title.includes('Mythical');
      const isCommon = title.includes('Common');

      result.push({
        value: prize,
        isRare,
        isMythical,
        isCommon,
        isLegendary,
      });
    }

    return result.sort(this._compareLegendaryState);
  }

  public matchLootboxesWithImages(data) {
    data.Goods.forEach(good => {
      const lootboxIdParts = good.LootboxId.split('_');
      const bonusStoreKeyPattern = lootboxIdParts.slice(1).map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('');
      const bonusStoreKey = Object.keys(data).find(key => key.includes(bonusStoreKeyPattern));
      if (bonusStoreKey) {
        good.image = data[bonusStoreKey];
      }
    });
  }

  private _compareLegendaryState(a, b) {
    if (a.isLegendary && !b.isLegendary) {
      return -1;
    } else if (!a.isLegendary && b.isLegendary) {
      return 1;
    }
    return 0;
  }

  private _mapPricesToGoods(goods: any[], products: any[]) {
    if (products) {
      return goods.map(item => {
        const lootboxId = item.LootboxId;
        const product = products?.find(price =>
          price?.bonus_group_attributes?.reference === lootboxId,
        );

        return {
          ...item,
          GoodsPrices: {
            price: product?.prices.reduce((acc, priceItem) => {
              acc[priceItem.currency] = this._common.isCryptoCurrency(priceItem.currency) ?
                priceItem.amount_cents / 100000000 : priceItem.amount_cents / 100;
              return acc;
            }, {}),
            id: product?.id,
          },
        };
      });
    } else {
      return goods;
    }
  }

  public async showBonusStoreModal(bonus: GoodsItem, boxImage: string, bonusShopType: BONUS_SHOP_TYPE, allBonuses: GoodsItem[]) {
    const allLootboxIds = allBonuses.map((e) => e.LootboxId);
    const component = await import('../../core/modal-v2/components/lazy/bonus-store-modal/bonus-store-modal.component');
    await this._modal.openLazy(component.BonusStoreModalComponent, {
      template: 'CLEAR',
      data: {
        ...bonus,
        bonusShopType,
        allLootboxIds,
        boxImage,
      },
    });
  }

}
