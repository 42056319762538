@if (controller && fields$ | async; as fields) {
  @if (controller.formForModal) {
    <form class="form" [formGroup]="controller.formForModal"
      (ngSubmit)="submitForm(controller.formForModal)">
      <div class="form__close icon-close" (click)="modal.close()"></div>
      <div class="form__title">{{'t.update-profile' | translate}}</div>
      <div class="form__description">{{ 't.migration-terms-desc' | translate}}</div>
      <div class="form__fields">
        @for (field of fields; track field) {
          @switch (true) {
            @case (field.type === FieldType.TEXT && field.name !== 'city' && field.name !== 'address' && field.field !== 'postal_code') {
              <app-form-input
                [control]="controller.formForModal.get(field.field)" [label]="field.label | translate" class="input-item">
                @switch (true) {
                  @case (field?.field === 'first_name' || field?.field === 'last_name') {
                    <ng-container [ngTemplateOutlet]="firstOrLastName"></ng-container>
                  }
                  @default {
                    <ng-container [ngTemplateOutlet]="defaultInput"></ng-container>
                  }
                }
                <ng-template #firstOrLastName>
                  <input (focus)="onFocus($event, field.field)" [formControlName]="field.field" class="input input--simple"
                         type="text" autocomplete="nope" [id]="field.field" InputLettersSymbols>
                </ng-template>
                <ng-template #defaultInput>
                  <input (focus)="onFocus($event, field.field)" [formControlName]="field.field" class="input input--simple"
                         type="text" [id]="field.field" autocomplete="nope">
                </ng-template>
              </app-form-input>
            }
            @case (field.type === FieldType.TEXT && field.name === 'city') {
              <app-form-input
                [control]="controller.formForModal.get(field.field)" [label]="field.label | translate" class="input-item">
                <input (focus)="onFocus($event, field.field)" [formControlName]="field.field" class="input input--simple"
                       type="text" [id]="field.field" autocomplete="off">
                <ng-container *ngTemplateOutlet="citiesSuggestionList"></ng-container>
              </app-form-input>
            }
            @case (field.type === FieldType.TEXT && field.name === 'address') {
              <app-form-input
                [control]="controller.formForModal.get(field.field)" [label]="field.label | translate" class="input-item">
                <input (focus)="onFocus($event, field.field)" [formControlName]="field.field" class="input input--simple"
                       type="text" autocomplete="off" [id]="field.field">
                <ng-container *ngTemplateOutlet="addressesSuggestionList"></ng-container>
              </app-form-input>
            }
            @case (field.field === 'postal_code') {
              <app-form-input class="input-item"
                              [control]="controller.formForModal.get(field.field)" [label]="field.label | translate">
                <input class="input input--simple" type="text" [ngClass]="{uppercase: this.userInfo.isCA}"
                       autocomplete="off"
                       (focus)="onFocus($event, field.field)" [formControlName]="field.field"
                       [mask]="userInfo.isCA ? 'S0S 0S0' : null" [id]="field.field"
                       (input)="userInfo.isCA ? controller.handlePostalCodeChange('postal_code') : null"
                       [dropSpecialCharacters]="false">
                <ng-container *ngTemplateOutlet="zipCodeSuggestionList"></ng-container>
              </app-form-input>
            }
            @case (field.type === FieldType.PHONE) {
              <app-form-input class="input-item"
                              [control]="controller.formForModal.get(field.field)">
                <app-phone-form [formControlName]="field.field"></app-phone-form>
              </app-form-input>
            }
            @case (field.type === FieldType.DATE) {
              <app-form-input
                [control]="controller.formForModal.get(field.field)" [label]="field.label | translate" class="input-item">
                <input #input (focus)="onFocus($event, field.field)" [formControlName]="field.field"
                       [placeholder]="rtl.isAr ? 'YYYY/MM/DD' : 'DD/MM/YYYY'" class="input input--simple"
                       [ngClass]="{'input--date-rtl': rtl.isAr}" type="text" [id]="field.field" [input]="input" inputmode="numeric"
                       appDobMask>
              </app-form-input>
            }
            @case (field.type === FieldType.SELECT) {
              <app-form-input class="input-item"
                              [control]="controller.formForModal.get(field.field)">
                @if (field.options | async; as options) {
                  <app-select [formControlName]="field.field" [id]="field.field"
                              class="select">
                    <app-select-header class="select__header">{{ field.label | translate }}</app-select-header>
                    <app-select-dropdown (focus)="onFocus($event, field.field)" class="select__dropdown">
                      @for (option of options; track option) {
                        <app-select-option class="select__option" [value]="option[0]">{{
                            field.field === 'country' || field.field === 'state' ? option[1] : option[1] | translate
                          }}</app-select-option>
                      }
                    </app-select-dropdown>
                  </app-select>
                }
              </app-form-input>
            }
            @case (field.type === FieldType.RADIO) {
              <app-form-input
                [control]="controller.formForModal.get(field.field)"
                class="input-item radio {{rtl.isAr ? 'radio--redesign' : ''}}">
                @if (field.options | async; as options) {
                  <div class="radio {{rtl.isAr ? 'radio--redesign' : ''}}">
                    @for (option of rtl.isAr ? (options | reverse) : options; track option) {
                      <label>
                        <input type="radio" [value]="option[0]" [id]="field.field" [formControlName]="field.field" />
                        <span>{{option[1] | translate}}</span>
                      </label>
                    }
                  </div>
                }
              </app-form-input>
            }
            @case (FieldType.CHECKBOX) {
              <app-form-input class="input-item"
                              [control]="controller.formForModal.get(field.field)">
                <label class="checkbox">
                  <input type="checkbox" [formControlName]="field.field" [id]="field.field">
                  <span class="checkbox__view"></span>
                  <span class="checkbox__label">{{ field.label | translate }}</span>
                </label>
              </app-form-input>
            }
          }
        }
        <div class="submit-container">
          @if (!controller.loading) {
            <button class="btn btn--filled-primary" type="submit">{{'btn.submit' | translate}}</button>
          }
          @if (controller.loading) {
            <app-preloader></app-preloader>
          }
        </div>
      </div>
    </form>
  }
}

              <ng-template #citiesSuggestionList>
                @if (citiesSuggestion && citiesSuggestion.length) {
                  <div
                    class="suggestion-list suggestion-list__address">
                    @for (city of citiesSuggestion; track city) {
                      <div
                        class="suggestion-list__item"
                        (click)="selectSuggestion(city, 'city')">
                        {{ city?.city }}
                      </div>
                    }
                  </div>
                }
              </ng-template>

              <ng-template #addressesSuggestionList>
                @if (addressesSuggestion && addressesSuggestion.length) {
                  <div
                    class="suggestion-list suggestion-list__address">
                    @for (suggestion of addressesSuggestion; track suggestion) {
                      <div class="suggestion-list__item" (click)="selectSuggestion(suggestion, 'address')">
                        <span>{{ suggestion?.formatted }}</span>
                      </div>
                    }
                  </div>
                }
              </ng-template>

              <ng-template #zipCodeSuggestionList>
                @if (zipCodeSuggestion && zipCodeSuggestion.length) {
                  <div
                    class="suggestion-list suggestion-list__address">
                    @for (suggestion of zipCodeSuggestion; track suggestion) {
                      <div class="suggestion-list__item" (click)="selectSuggestion(suggestion, 'postal_code')">
                        <span>{{ suggestion?.postcode }}</span>
                      </div>
                    }
                  </div>
                }
              </ng-template>
