import { NgModule } from '@angular/core';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormCheckboxToggleComponent } from './form-checkbox-toggle/form-checkbox-toggle.component';
import { FormInputComponent } from './form-input/form-input.component';


@NgModule({
  imports: [
    FormInputComponent,
    FormCheckboxComponent,
    FormCheckboxToggleComponent
  ],
  exports: [
    FormInputComponent,
    FormCheckboxComponent,
    FormCheckboxToggleComponent,
  ],
})
export class FormControlsModule { }
