import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {delay, filter, map, tap} from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import {fromEvent, merge} from 'rxjs';
import {FormsErrorHandlerService} from '../../../services/forms-error-handler.service';
import {PlatformService} from '../../../services/platform.service';
import {TranslationService} from '../../../shared/translation/translation.service';
import {WindowService} from '../../../services/window.service';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';
import { NgClass } from '@angular/common';
import { TranslatePipe } from '../../../shared/translation/translate.pipe';

export enum TooltipPlacement {
  TOP = 'top',
  AUTO = 'auto'
}

@UntilDestroy()
@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [
    ClickOutsideDirective,
    NgClass,
    TranslatePipe
],
})
export class FormInputComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild('input') public input: ElementRef;
  /**
   * Text label over input
   */
  @Input() label;

  @Input() errorType: 'bottomError' | 'tooltipError' = 'tooltipError';

  /**
   * List of errors for current control
   */
  @Input() errors: Array<string> = [];

  /**
   * Form control
   */
  @Input() control: AbstractControl;

  /**
   * Place tooltip in provided placement option
   */
  @Input() tooltipPlacement: TooltipPlacement = TooltipPlacement.AUTO;

  /**
   * Automatically hide tooltip
   */
  @Input() tooltipAutoHide: boolean;

  errorEl: HTMLElement;

  public isRight = true;

  private _destroyed: boolean;

  /**
   * Is tooltip automatically hidden
   */
  private _hidden: boolean;

  /**
   * Listen click outside
   */
  private _listenClick: boolean;

  /**
   * True if input focused
   */
  public isFocused: boolean;

  /**
   * True if placeholder present
   */
  public isPlaceholderOrValuePresent: boolean;

  constructor(
    private _formErrors: FormsErrorHandlerService,
    private _el: ElementRef,
    private _platform: PlatformService,
    private _translateService: TranslationService,
    private _window: WindowService,
  ) {
  }

  ngOnChanges() {
    if (this.errors && this._platform.isBrowser && window.innerWidth > 768) {
      this._updateTooltipContent();
    }
  }


  ngOnInit() {
    if (this.control && this.errors && this.errors.length === 0) {

      this.errors = this._formErrors.errors(this.control);

      merge(
        this.control.statusChanges,
        this.control.valueChanges
      ).pipe(
        untilDestroyed(this),
        tap(() => {
          this.errors = this._formErrors.errors(this.control);
          this._updateTooltipContent();
        })
      ).subscribe();
    }
  }

  ngOnDestroy(): void {
    this._destroyed = true;
  }

  ngAfterViewInit() {
    this._checkIfInputFocusAndPlaceholderValue();
  }

  /**
   * Handle click outside of element
   */
  clickedOutside() {
    if (this.tooltipAutoHide && this._listenClick && this.errorEl && this.errorEl.classList.contains('visible')) {
      this.errorEl.classList.remove('visible');
      this._hidden = true;
      this._listenClick = false;
    }
  }

  /**
   * Update inner html of error tooltip
   *
   * @private
   */
  private _updateTooltipContent() {
    if (this.errors && this.errors.length && this.errorEl) {
      let content = '';

      this.errors.forEach(error => {
        content += `<div class="error-label" >${this._translateService.translate(error)}</div>`;
      });

      this.errorEl.innerHTML = content;
    }
  }

  fixCaretPosition() {
    if (this._window.isiOSDevices) {
      const input = this.input.nativeElement.querySelector('input');
      if (input?.type === 'text' || input?.type === 'number') {
        this.input.nativeElement.style.paddingRight = '1px';
        setTimeout(() => {
          this.input.nativeElement.style.paddingRight = '0px';
        }, 60);
      }
    }
  }

  private _checkIfInputFocusAndPlaceholderValue() {
    if (this._platform.isBrowser) {
      setTimeout(() => {
        if (!!this.input?.nativeElement) {
          const input = this.input?.nativeElement.querySelector('input');
          this.isPlaceholderOrValuePresent = Boolean(input?.placeholder || input?.value);

          if (input) {
            merge(fromEvent(input, 'focus'), fromEvent(input, 'blur')).pipe(
              untilDestroyed(this),
              map((event) => [event, this.input?.nativeElement.querySelector('input')]),
              filter(data => !!data[1]),
              delay(0),
              tap(data =>  {
                const event: FocusEvent = data[0];
                const input = data[1];
                const {type} = event;
                switch (type) {
                  case 'focus':
                    this.isFocused = true;
                    break;
                  case 'blur':
                    this.isFocused = false;
                    break;
                }
                this.isPlaceholderOrValuePresent = Boolean(input.placeholder || input.value);
              })
            ).subscribe();
          }
        }
      });
    }
  }

  /**
   * If click for label - focus input
   */
  public onLabelClick() {
    this.input?.nativeElement?.querySelector('input')?.focus();
  }
}
