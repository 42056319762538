import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import { FormInputComponent } from '../form-input/form-input.component';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { SafePipe } from '../../../shared/pipes/safe.pipe';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  standalone: true,
  imports: [
    FormInputComponent,
    NgStyle,
    NgTemplateOutlet,
    SafePipe
],
})
export class FormCheckboxComponent {
  @Input() checked: boolean;
  @Input() label: string;
  @Input() labelTpl: TemplateRef<any>;
  @Input() borderColor: string = '';
  @Input() control: AbstractControl = new FormControl();
  @Input() styles: {[index: string]: string};
  @Output() change$: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {}

  public change($event) {
    this.control?.setValue($event?.target?.checked);
    this.change$.next($event);
  }
}
